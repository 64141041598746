import { api } from '../baseRtk.api'
import { User } from 'src/models/user/user'
import { AgGridGetResponse, AgGridInfiniteGetResponse, FilterParams } from 'src/config/globalConfig'
import { Employee } from 'src/models/user/employee'
import camelcaseKeys from 'camelcase-keys'
import { UserPassword } from 'src/models/user/userPassword'
import { InspectorFilter } from 'src/models/user/inspector'
import { ChangelogCreatedBy } from 'src/models/changelog/changelogRecord'

export const usersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUserProfile: build.query<User, void>({
            query: () => ({
                url: 'auth/profile',
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        updateCurrentUserProfile: build.mutation<User, User>({
            query: (user) => ({
                url: 'auth/profile',
                method: 'PUT',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        getAllEmployees: build.query<AgGridInfiniteGetResponse<Employee>, FilterParams>({
            query: (params) => ({
                url: '/users/',
                method: 'GET',
                params: { role: 'employee', ...params },
            }),
            providesTags: ['User'],
        }),
        getAllInspectors: build.query<AgGridInfiniteGetResponse<Employee>, FilterParams>({
            query: (params) => ({
                url: '/users/',
                method: 'GET',
                params: { role: 'inspector', ...params },
            }),
            providesTags: ['User'],
        }),
        getAllApplicant: build.query<AgGridInfiniteGetResponse<Employee>, FilterParams>({
            query: (params) => ({
                url: '/users/',
                method: 'GET',
                params
            }),
            providesTags: ['User'],
        }),
        getAllApplicantForAutocomplete: build.query<{ id: string, inn: string }[], void>({
            query: () => ({
                url: '/users/for-applications',
                method: 'GET',
            }),
            providesTags: ['User', 'FairApplication', 'MarketApplication'],
        }),
        getAllApplicantForMarket: build.query<AgGridInfiniteGetResponse<Employee>, FilterParams>({
            query: (params) => ({
                url: '/market/applicants',
                method: 'GET',
                params
            }),
            providesTags: ['User'],
        }),
        getUser: build.query<User, string>({
            query: (id) => ({
                url: `/users/${id}`,
                method: 'GET',
            }),
            providesTags: ['User'],
            transformResponse: (userDto: any) => {
                return camelcaseKeys(userDto, { deep: true })
            }
        }),
        createUser: build.mutation<undefined, Employee>({
            query: (user) => ({
                url: `/users/new`,
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        updateUser: build.mutation<undefined, User>({
            query: (user) => ({
                url: `/users/${user.id}`,
                method: 'PUT',
                body: user,
            }),
            invalidatesTags: ['User', 'MarketApplication', 'FairApplication'],
        }),
        deleteUser: build.mutation<undefined, string>({
            query: (id) => ({
                url: `/users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
        updateCurrentUserPassword: build.mutation<undefined, UserPassword>({
            query: (password) => ({
                url: 'auth/profile/change-password',
                method: 'PUT',
                body: password,
            }),
            invalidatesTags: ['User'],
        }),
        updateUserPassword: build.mutation<undefined, UserPassword>({
            query: (password) => ({
                url: `users/${password.userId}/change-password`,
                method: 'PUT',
                body: password,
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const {
    useGetAllApplicantForAutocompleteQuery, 
    useGetAllApplicantQuery,
    useGetAllApplicantForMarketQuery,
    useGetAllEmployeesQuery,
    useGetAllInspectorsQuery,
    useCreateUserMutation,
    useGetUserQuery,
    useDeleteUserMutation,
    useGetCurrentUserProfileQuery,
    useUpdateUserMutation,
    useUpdateCurrentUserProfileMutation,
    useUpdateCurrentUserPasswordMutation,
    useUpdateUserPasswordMutation
} = usersApi

export const {
    endpoints: { getCurrentUserProfile, getAllEmployees, getAllApplicant, getAllInspectors, getAllApplicantForMarket },
} = usersApi

export const toUserFromDto = (userDto: any): ChangelogCreatedBy => {
    return {
        id: userDto.id,
        email: userDto.email,
        role: userDto.role.role_name,
        fullName: userDto.full_name,
        phoneNumber: userDto.phone_number,
    }
}
