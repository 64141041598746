export enum UserRole {
    Administrator = "administrator",
    Entrepreneur = "entrepreneur",
    Individual = "individual",
    Entity = "entity",
    FairEmployee = "fairEmployee",
    Director = "director",
    MarketSupport = "marketSupport",
    MarketEmployee = "marketEmployee",
    Inspector = "inspector",
    LaboratoryAssistant = "laboratoryAssistant",
    CommitteeEmployee = "committeeEmployee"
}

export enum UserPermission {
    // Рынки
    EditProfile,
    CreateEditMarket,
    EditMarketTradingPlace,
    ReadApplicationsList,
    EditReferenceInformation
}