import { List } from '@mui/material'
import React from 'react'
import { useAccessControl } from 'src/api/auth/hooks/useAccessControl'
import { UserRole } from 'src/config/rolesPermissionsMap'

export interface MenuProps {
    children: React.ReactNode | React.ReactNode[]
    roles?: UserRole[]
    depth?: number
}

const PADDING_LEFT_SPACING = 4

const Menu = (props: MenuProps) => {
    const { isAllowed } = useAccessControl({
        roles: props.roles
    });

    if (!isAllowed) return null;

    return (
        <List component='div' disablePadding sx={{
            '& .MuiListItemButton-root': {
                pl: (props.depth ?? 0) * PADDING_LEFT_SPACING + 2,
                pr: 2
            }
        }}>
            {props.children}
        </List>
    )
}

export default Menu