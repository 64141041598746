export enum STATUS_SMEV {
    DATA_SENT = 'Данные отправлены на проверку',
    INVALID_DATA = 'Некорректные данные',
    DATA_RECEIVED = 'Данные получены',
    DATA_RECEIVED_COINCIDENCE = 'Данные получены и совпадают',
    DATA_RECEIVED_NOT_COINCIDENCE = 'Данные получены и не совпадают',
    SMEV_VALIDATION_ERROR = 'Ошибка валидации СМЭВ',
    INFO_NOT_FOUND = 'Сведения не найдены',
    INFO_CANNOT_BE_PROVIDED = 'Сведения не могут быть предоставлены в электронном виде',
    INVALID_INN = 'Некорректный ИНН',
}

export enum SMEV_DATA_TYPE {
    STATE_REGISTER_EXTRACT = 'Выписка из ЕГРЮЛ/ЕГРИП',
    ENTREPRENEURSHIP_REGISTER = 'Предоставление сведений из реестра малого и среднего предпринимательства',
}

export type SmevFts = 
    Record<typeof entitySmevFields[number], string | boolean | undefined>
    & Record<typeof entrepreneurSmevFields[number], string | boolean | undefined>
    & {
        id: string
        inn: string
        statusSmev: STATUS_SMEV
    }

export const entrepreneurSmevFields = [
    'email',
    'surname',
    'firstname',
    'patronymic',
    'citizenship',
    'phoneNumber',
    'registrationAddress',
    'actualAddress',
    'ogrnip',
    'ogrnipDate',
    'registrationDate',
    'registrationAuthority',
    'inn',
    'innRegistrationDate',
    'activities',
    'isFarmer',
    'farmerDocumentDate',
    'farmerDocumentNumber',
    'isSmallBusinessEntity',
] as const

export const entitySmevFields = [
    'email',
    'phoneNumber',
    'registrationAddress',
    'actualAddress',
    'companyFullName',
    'abbreviatedName',
    'legalForm',
    'managerFullName',
    'smevPosition',
    'ogrn',
    'ogrnDate',
    'registrationDate',
    'registrationAuthority',
    'inn',
    'innRegistrationDate',
    'kpp',
    'activities',
    'isFarmer',
    'farmerDocumentDate',
    'farmerDocumentNumber',
    'isSmallBusinessEntity',
] as const