import { rest } from 'msw'

export const fairHandlers = [
    // rest.get('http://reestr61.local/api/fairs/1', (req, res, ctx) => {
    //     return res(
    //         ctx.delay(300),
    //         ctx.json(
    //             {
    //                 name: 'Ярмарка', documents: {
    //                     rangeOfGoodsFair: [{
    //                         fileId: 1,
    //                         fileName: 'TEST FILE NAME 1',
    //                         fileSize: 3000
    //                     },
    //                     {
    //                         fileId: 1,
    //                         fileName: 'TEST FILE NAME 2',
    //                         fileSize: 3000
    //                     }]
    //                 }
    //             },
    //         )
    //     )
    // }),
    //справочная информация
    rest.get('http://reestr61.local/api/information/fairs/1', (req, res, ctx) => {
        return res(
            ctx.delay(300),
            ctx.json(
                {
                    id: '1',
                    title: 'План организации ярмарок выходного дня и региональных в санкт-петербурге в 2021 году',
                    documents: {
                        info: [{
                            fileId: 1,
                            fileName: 'TEST FILE NAME 1',
                            fileSize: 300
                        }],
                    }
                }
            )
        )
    }),
    rest.get('http://reestr61.local/api/information/fairs', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    id: '1',
                    title: 'План организации ярмарок выходного дня и региональных в санкт-петербурге в 2021 году',
                    documents: {
                        info: [{
                            fileId: 1,
                            fileName: 'TEST FILE NAME 1',
                            fileSize: 300
                        }],
                    }
                },
                {
                    id: '2',
                    title: 'Распоряжение Комитета по промышленной политике, инновациям и торговле Санкт-Петербурга от 12.11.2021 № 5631-р-р',
                    documents: {
                        info: [{
                            fileId: 2,
                            fileName: 'TEST FILE NAME 2',
                            fileSize: 200
                        }],
                    }
                }
            ])
        )
    }),
]

