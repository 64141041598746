import { rest } from 'msw'

const appeal = {
    id: 1,
    product: {
        group: 'string',
        kind: 'string',
        name: 'Королевские дамплинги с креветками',
        productionDate: '2020-09-25',
        weight: '385 г',
        volume: '385 г',
        price: '199',
        manufacturer: 'ООО "СИДЖЕЙ РАВИОЛЛО РУС"',
        company: 'Гипермаркет "ОКЕЙ"',
        companyAddress: 'ул. Руставели, 43',
        purchaseDate: '2020-12-24',
        isReceiptSaved: true,
        // type?: string;
        // category?: string;
        // class?: string;
    },
    applicant: {
        fullName: 'Никитина Наталья Николаевна',
        email: 'nnnikitina@mail.ru',
        postalAddress: '195252 Санкт-Петербург, ул. Софьи Ковалевской 11-1-55',
        phoneNumber: '+7(921)416-29-37'
    },
    type: 'Продовольственное',
    way: 'Устно/телефон',
    date: '2022-04-02',
    content: 'Прошу провести проверку качества продукта, употребление которого вызвало сильное пищевое отравление.',
    inspectionConclusion: {
        result: 'Всё норм',
        conclusions: 'Пусть продавцы следят, чтобы морозильные камеры нормально морозили',
        documents: [
            {
                fileId: 2,
                fileName: 'Документ-приложение к акту проверки',
                filePath: '',
                fileSize: 200,
            }
        ]
    },
    documents: [
        {
            fileId: 1,
            fileName: 'TEST NAME',
            filePath: '',
            fileSize: 200,
        }
    ],
}
export const inspectionHandlers = [
    rest.get('http://reestr61.local/api/agreements', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                count: 2,
                rows: [
                    {
                        id: 1,
                        signer: 'Санкт-Петербургское государственное бюджетное учреждение «Центр контроля качества товаров (продукции), работ и услуг»',
                        contractConclusionDate: '2018-01-01',
                        contractGrounds: '31.12.2022',
                        commercialNetwork: {
                            name: 'ООО "СИДЖЕЙ РАВИОЛЛО РУС"',
                            legalAddress: 'string',
                            inn: 'string',
                            kpp: 'string',
                            contactPerson: 'string',
                            contactPersonPhoneNumber: 'string',
                            signer: 'string',
                        }
                    },
                    {
                        id: 2,
                        signer: 'Санкт-Петербургское государственное бюджетное учреждение «Центр контроля качества товаров (продукции), работ и услуг»',
                        contractConclusionDate: '2018-01-01',
                        contractGrounds: '31.12.2022',
                        commercialNetwork: {
                            name: 'ООО "ТРАКТОР"',
                            legalAddress: 'string',
                            inn: 'string',
                            kpp: 'string',
                            contactPerson: 'string',
                            contactPersonPhoneNumber: 'string',
                            signer: 'string',
                        }
                    },
                ],
            })
        )
    }),

    rest.get('http://reestr61.local/api/agreements/1', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                signer: 'Санкт-Петербургское государственное бюджетное учреждение «Центр контроля качества товаров (продукции), работ и услуг»',
                contractConclusionDate: '2018-01-01',
                contractGrounds: '31.12.2022',
                commercialNetwork: {
                    name: 'ООО "СИДЖЕЙ РАВИОЛЛО РУС"',
                    legalAddress: 'string',
                    inn: 'string',
                    kpp: 'string',
                    contactPerson: 'string',
                    contactPersonPhoneNumber: 'string',
                    signer: 'string',
                }
            })
        )
    }),



    rest.get('http://reestr61.local/api/appeals', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                count: 1,
                rows: [
                    {
                        id: 1,
                        product: {
                            group: 'string',
                            kind: 'string',
                            name: 'Королевские дамплинги с креветками',
                            productionDate: '2020-09-25',
                            weight: '385 г',
                            volume: '385 г',
                            price: '199',
                            manufacturer: 'ООО "СИДЖЕЙ РАВИОЛЛО РУС"',
                            company: 'Гипермаркет "ОКЕЙ"',
                            companyAddress: 'ул. Руставели, 43',
                            purchaseDate: '2020-12-24',
                            isReceiptSaved: true,
                            // type?: string;
                            // category?: string;
                            // class?: string;
                        },
                        applicant: {
                            fullName: 'Никитина Наталья Николаевна',
                            email: 'nnnikitina@mail.ru',
                            postalAddress: '195252 Санкт-Петербург, ул. Софьи Ковалевской 11-1-55',
                            phoneNumber: '+7(921)416-29-37'
                        },
                        type: 'Продовольственное',
                        way: 'Устно/телефон',
                        date: '2022-04-02',
                        content: 'Прошу провести проверку качества продукта, употребление которого вызвало сильное пищевое отравление.',
                        // inspectionConclusion?
                        documents: [
                            {
                                fileId: 1,
                                fileName: 'TEST NAME',
                                filePath: '',
                                fileSize: 200,
                            }
                        ]
                    },
                ],
            })
        )
    }),
    rest.get('http://reestr61.local/api/appeals/1', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json(appeal)
        )
    }),


    rest.get('http://reestr61.local/api/refferals', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                count: 1,
                rows: [
                    {
                        id: 1,
                        number: '3212',
                        dateOf: '2022-04-02',
                        timeOf: '14:00',
                        normativeDocuments: 'ГОСТ 31433-2017 ТРТС 22/235',
                        status: 'refferalPreparation',
                        inspectionDetail: {
                            dateOf: '2022-05-01',
                            startTime: '11:00',
                            endTime: '17:00',
                            actNumber: '23213'
                        },
                        tradeCompany: {
                            name: 'Гипермаркет "ОКЕЙ"',
                            address: 'ул. Руставели, 43',
                            legalAddress: 'ул. Базовичков, 63',
                            phoneNumber: '+74849834939',
                            headFullName: 'Иванов Сергей Дмитриевич',
                            headPosition: 'Директор',
                            sampleStorageArea: 'Склад №5',
                            reprFullName: 'Иванов Дмитрий Сергеевич',
                            reprPosition: 'Заместитель директора'
                        },
                        tradeAgreement: {
                            id: 1,
                            signer: 'Санкт-Петербургское государственное бюджетное учреждение «Центр контроля качества товаров (продукции), работ и услуг»',
                            contractConclusionDate: '2018-01-01',
                            contractGrounds: '31.12.2022',
                            commercialNetwork: {
                                name: 'ООО "СИДЖЕЙ РАВИОЛЛО РУС"',
                                legalAddress: 'string',
                                inn: 'string',
                                kpp: 'string',
                                contactPerson: 'string',
                                contactPersonPhoneNumber: 'string',
                                signer: 'string',
                            }
                        },
                        inspectionTeam: {
                            inspector: 'fdsf',
                            testCoordinatorFullName: 'Иванов Сергей Дмитриевич',
                            testCoordinatorPosition: 'Координатор',
                            headFullName: 'Иванов Сергей Дмитриевич',
                            headPosition: 'Руководитель',
                        }
                    },
                ],
            })
        )
    }),
    rest.get('http://reestr61.local/api/refferals/1', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({
                id: 1,
                number: '3212',
                dateOf: '2022-04-02',
                timeOf: '14:00',
                normativeDocuments: 'ГОСТ 31433-2017 ТРТС 22/235',
                status: 'refferalPreparation',
                inspectionDetail: {
                    dateOf: '2022-05-01',
                    startTime: '11:00',
                    endTime: '17:00',
                    actNumber: '23213'
                },
                tradeCompany: {
                    name: 'Гипермаркет "ОКЕЙ"',
                    address: 'ул. Руставели, 43',
                    legalAddress: 'ул. Базовичков, 63',
                    phoneNumber: '+74849834939',
                    headFullName: 'Иванов Сергей Дмитриевич',
                    headPosition: 'Директор',
                    sampleStorageArea: 'Склад №5',
                    reprFullName: 'Иванов Дмитрий Сергеевич',
                    reprPosition: 'Заместитель директора'
                },
                tradeAgreement: {
                    id: 1,
                    signer: 'Санкт-Петербургское государственное бюджетное учреждение «Центр контроля качества товаров (продукции), работ и услуг»',
                    contractConclusionDate: '2018-01-01',
                    contractGrounds: '31.12.2022',
                    commercialNetwork: {
                        name: 'ООО "СИДЖЕЙ РАВИОЛЛО РУС"',
                        legalAddress: 'string',
                        inn: 'string',
                        kpp: 'string',
                        contactPerson: 'string',
                        contactPersonPhoneNumber: 'string',
                        signer: 'string',
                    }
                },
                inspectionTeam: {
                    inspector: 'fdsf',
                    testCoordinatorFullName: 'Иванов Сергей Дмитриевич',
                    testCoordinatorPosition: 'Координатор',
                    headFullName: 'Иванов Сергей Дмитриевич',
                    headPosition: 'Руководитель',
                },
                productSamples: [
                    {
                        id: 1,
                        manufacturerCountry: 'asfsd',
                        manufacturer: 'asfsd',
                        supplier: 'asfsd',
                        group: 'asfsd',
                        name: 'asfsd',
                        number: 'asfsd',
                        quantity: 'asfsd',
                        productionDate: '2018-01-01',
                        expirationDate: '2018-01-01',
                        weight: 'asfsd',
                        volume: 'asfsd',
                    }
                ]
            })
        )
    }),


    rest.get('http://reestr61.local/api/refferals/1/samples/1', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json(
                {
                    id: 1,
                    manufacturerCountry: 'Россия',
                    manufacturer: 'ООО МакПром',
                    supplier: 'РЦ Шумары',
                    group: 'А',
                    name: 'Макаронные изделия',
                    number: 343,
                    quantity: 3,
                    productionDate: '2020-04-29',
                    expirationDate: '2021-09-29',
                    weight: '300 г.',
                    volume: '300 мл.',
                    ttnNumber: '03-57437',
                    ttnDate: '2021-10-15',
                    ttnLotSize: 20,
                    selectedLotSize: 3,
                    selectedProductNormativeDocuments: 'Образец от торгового предприятия',
                    developmentProductNormativeDocuments: 'ГОСТ 32234',
                    packagingCondition: 'Не повреждена',
                    selectionActNote: 'Другая маркировка',
                    documents: [
                        {
                            fileId: 1,
                            fileName: 'TEST NAME',
                            filePath: '',
                            fileSize: 200,
                        }
                    ],

                    testProgram: {
                        organolapticIndicators: true,
                        safetyIndicators: true,
                        physicalСhemicalIndicators: true,
                        microbiologicalIndicators: true,
                        toxicElements: false,
                        pesticides: true,
                        mycotoxins: false,
                        radionuclides: true,
                        nitrates: false,
                        nitrites: false,
                        histologicalIdentification: false,
                        antibiotics: false,
                        benzopyrene: false,
                        nitrosamines: false,
                        polychlorinatedBiphenyls: false,
                        histamine: false,
                        oxymethylfurfural: false,
                        harmfulImpurities: false,
                        gmo: true,
                        oxidativeDamage: false,
                    },

                    sampleInfo: {
                        registrationDate: '2021-09-29',
                        samplingInformation: 'dsdsadsad',
                        additionalInformation: 'saddadsad dsfgsfd',
                    },
                    testProtocol: {
                        testIndicators: [
                            {
                                group: 'organolapticIndicators',
                                name: 'КМАФАнМ, КОЕ/г',
                                normativeValue: 'Не более 1*10^6',
                                actualValue: '5,2*10^6',
                                normativeDocument: 'ГОСТ 10444Ю15-94, ГОСТ ISO 7218-2015',
                            },
                            {
                                group: 'physicalСhemicalIndicators',
                                name: 'fgdfdsfdfsdf',
                                normativeValue: 'Не более 1*10^6',
                                actualValue: '5,2*10^6',
                                normativeDocument: 'ГОСТ 10444Ю15-94',
                            },
                            {
                                group: 'toxicElements',
                                name: 'asA2DSG',
                                normativeValue: 'Не более 1*10^6',
                                actualValue: '5,2*10^6',
                                normativeDocument: 'ГОСТ ISO 7218-2015',
                            }
                        ],
                        number: 123,
                        registrationDate: '2021-10-05',
                        approvalDate: '2021-10-10',
                        testStartDate: '2021-10-05',
                        testEndDate: '2021-10-07',
                        conclusion: 'dfsdfsdfsdfs',
                    }
                }
            )
        )
    }),
]

