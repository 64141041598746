import { UserPermission, UserRole } from 'src/config/rolesPermissionsMap'

interface Route {
  path: string,
  component: () => Promise<any>
  roles?: UserRole[]
  permissions?: UserPermission[]
}


const privateRoutes: Route[] = [
  {
    path: '/',
    component: () => import('src/views/user/pages/UserProfileViewPage'),
  },
  {
    path: '/notifications',
    component: () => import('src/views/notification/NotificationsPage'),
    roles: [
      UserRole.Individual, 
      UserRole.Entity, 
      UserRole.Entrepreneur, 
      UserRole.FairEmployee, 
      UserRole.MarketEmployee, 
      UserRole.MarketSupport,
      UserRole.Director,
      UserRole.Inspector, 
      UserRole.LaboratoryAssistant
    ]
  },

  //пользователи
  {
    path: '/users/applicants',
    component: () => import('src/views/user/pages/ApplicantListPage'),
    roles: [UserRole.Administrator]
  },
  {
    path: '/users/employees',
    component: () => import('src/views/user/pages/EmployeeListPage'),
    roles: [UserRole.Administrator]
  },
  {
    path: '/users/markets/applicants',
    component: () => import('src/views/market/pages/MarketApplicantListPage'),
    roles: [UserRole.Director, UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.CommitteeEmployee]
  },
  {
    path: '/users/markets/applicants/:userId',
    component: () => import('src/views/user/pages/ApplicantViewPage'),
    roles: [UserRole.Director, UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.CommitteeEmployee]
  },
  {
    path: '/users/inspectors',
    component: () => import('src/views/user/pages/InspectorListPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/users/inspectors/:userId',
    component: () => import('src/views/user/pages/InspectorViewPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/users/inspectors/:userId/changelog',
    component: () => import('src/views/user/pages/InspectorChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/users/new',
    component: () => import('src/views/user/pages/EmployeeCreatedPage'),
    roles: [UserRole.Administrator]
  },
  {
    path: '/users/:userId',
    component: () => import('src/views/user/pages/UserProfileViewPage'),
    roles: [UserRole.Administrator]
  },
  {
    path: '/users/:userId/edit',
    component: () => import('src/views/user/pages/UserProfileEditPage'),
    roles: [UserRole.Administrator, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.Director, UserRole.FairEmployee]
  },
  {
    path: '/profile',
    component: () => import('src/views/user/pages/UserProfileViewPage'),
  },
  {
    path: '/profile/edit',
    component: () => import('src/views/user/pages/UserProfileEditPage'),
  },
  {
    path: '/profile/privacy',
    component: () => import('src/views/user/pages/ProfilePrivacyPage'),
  },
  {
    path: '/users/:userId/edit/change-password',
    component: () => import('src/views/auth/PasswordChangeFormPage'),
    roles: [UserRole.Administrator]
  },
  {
    path: '/profile/edit/change-password',
    component: () => import('src/views/auth/PasswordChangeFormPage'),
    roles: [UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },

  //ярмарки
  {
    path: '/fairs/add',
    component: () => import('src/views/fair/pages/FairFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/fairs/:id/edit',
    component: () => import('src/views/fair/pages/FairFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/fairs/:id/dates/edit',
    component: () => import('src/views/fair/pages/FairDatesEditFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/fairs',
    component: () => import('src/views/fair/pages/FairsListPage'),
    roles: [UserRole.FairEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur, UserRole.CommitteeEmployee]
  },
  {
    path: '/fairs/:fairId',
    component: () => import('src/views/fair/pages/FairInfoPage'),
    roles: [UserRole.FairEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur, UserRole.CommitteeEmployee]
  },
  {
    path: '/fairs/:fairId/additional',
    component: () => import('src/views/fair/pages/FairAdditionalFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/fairs/:fairId/sectors/:sectorId/application',
    component: () => import('src/views/fair/pages/FairApplicationUserFormPage'),
    roles: [UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/fairs/:fairId/sectors/:sectorId/trading-places',
    component: () => import('src/views/fair/pages/FairTradingPlacesPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/fairs/:fairId/sectors/:sectorId/trading-places/:placeId/changelog',
    component: () => import('src/views/fair/pages/FairTradingPlaceChangelogPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/contracts/fair',
    component: () => import('src/views/fair/pages/FairContractsPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/fair/contracts/:contractId',
    component: () => import('src/views/fair/pages/FairContractInfoPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/fair/contracts/:contractId/edit',
    component: () => import('src/views/fair/pages/FairContractEditPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/fairs/:fairId/protocol',
    component: () => import('src/views/fair/pages/FairProtocolPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/fairs/:fairId/changelog',
    component: () => import('src/views/fair/pages/FairChangelogPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/fairs/:fairId/assignment-statuses',
    component: () => import('src/views/fair/pages/FairAssignmentStatusesPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/fairs/:fairId/assignment-numbers',
    component: () => import('src/views/fair/pages/FairAssignmentNumbersPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee]
  },


  //информация
  {
    path: '/information/fairs',
    component: () => import('src/views/fair/pages/FairsHelpInfoListPage'),
    roles: [UserRole.FairEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur, UserRole.CommitteeEmployee]
  },
  {
    path: '/information/markets',
    component: () => import('src/views/market/pages/MarketHelpInfoListPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/information/fairs/add',
    component: () => import('src/views/fair/pages/FairsHelpInfoFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/information/fairs/:helpInfoId/edit',
    component: () => import('src/views/fair/pages/FairsHelpInfoFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/information/markets/add',
    component: () => import('src/views/market/pages/MarketsHelpInfoFormPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee]
  },
  {
    path: '/information/markets/:helpInfoId/edit',
    component: () => import('src/views/market/pages/MarketsHelpInfoFormPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee]
  },

  //заявки на ярмарки
  {
    path: '/applications/fair',
    component: () => import('src/views/fair/pages/FairApplicationListPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/applications/fair/add',
    component: () => import('src/views/fair/pages/FairApplicationOrganizerFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/applications/fair/:id',
    component: () => import('src/views/fair/pages/FairApplicationInfoPage'),
    roles: [UserRole.FairEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/applications/fair/:id/edit',
    component: () => import('src/views/fair/pages/FairApplicationEditFormPage'),
    roles: [UserRole.FairEmployee]
  },
  {
    path: '/fair/:fairId/waiting-journal',
    component: () => import('src/views/fair/pages/FairWaitingJournalPage'),
  },
  {
    path: '/fairs/:fairId/applications',
    component: () => import('src/views/fair/pages/OneFairApplicationListPage'),
  },

  //рынки
  {
    path: '/markets',
    component: () => import('src/views/market/pages/MarketsListPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/markets/documents',
    component: () => import('src/views/user/pages/UserDocumentsPage'),
    roles: [UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/markets/:marketId',
    component: () => import('src/views/market/pages/MarketInfoPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/markets/add',
    component: () => import('src/views/market/pages/MarketFormPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee]
  },
  {
    path: '/markets/:marketId/edit',
    component: () => import('src/views/market/pages/MarketFormPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee]
  },
  {
    path: '/markets/:marketId/changelog',
    component: () => import('src/views/market/pages/MarketChangelogPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee]
  },
  //торговые места рынков
  {
    path: '/markets/:marketId/place/add',
    component: () => import('src/views/market/pages/MarketPlaceFormPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee]
  },
  {
    path: '/markets/:marketId/place/:placeId',
    component: () => import('src/views/market/pages/MarketPlaceInfoPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/markets/:marketId/place/:placeId/edit',
    component: () => import('src/views/market/pages/MarketPlaceFormPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee]
  },
  {
    path: '/markets/:marketId/places',
    component: () => import('src/views/market/pages/MarketPlaceListPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/markets/:marketId/places/:placeId/changelog',
    component: () => import('src/views/market/pages/MarketPlaceChangelogPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee]
  },
  //типы торговых мест рынков
  {
    path: '/markets/:marketId/places/types/add',
    component: () => import('src/views/market/pages/MarketPlaceTypeAddFormPage'),
    roles: [UserRole.Director, UserRole.MarketEmployee, UserRole.MarketSupport]
  },
  {
    path: '/markets/:marketId/places/types/:typeId/edit',
    component: () => import('src/views/market/pages/MarketPlaceTypeEditFormPage'),
    roles: [UserRole.Director, UserRole.MarketEmployee, UserRole.MarketSupport]
  },
  //заявки на рынки
  {
    path: 'markets/:marketId/application/',
    component: () => import('src/views/market/pages/MarketApplicationAddFormPage'),
    roles: [UserRole.MarketSupport, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/applications/market',
    component: () => import('src/views/market/pages/MarketApplicationListPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/applications/market/:applicationId',
    component: () => import('src/views/market/pages/MarketApplicationInfoPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]
  },
  {
    path: '/applications/market/:applicationId/edit',
    component: () => import('src/views/market/pages/MarketApplicationEditFormPage'),
    roles: [UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.Director]
  },
  {
    path: '/applications/market/closing-contract/',
    component: () => import('src/views/market/pages/MarketClosingContractApplicationListPage'),
    roles: [UserRole.Director, UserRole.MarketSupport, UserRole.MarketEmployee, UserRole.CommitteeEmployee]
  },
  {
    path: '/contracts/market/:contractId',
    component: () => import('src/views/market/pages/MarketContractPage'),
    roles: [UserRole.MarketEmployee, UserRole.Director, UserRole.MarketSupport, UserRole.CommitteeEmployee]
  },
  {
    path: '/contracts/market/:contractId/edit',
    component: () => import('src/views/market/pages/MarketContractEditFormPage'),
    roles: [UserRole.MarketEmployee, UserRole.Director, UserRole.MarketSupport]
  },
  {
    path: '/contracts/market/by-market/:marketId',
    component: () => import('src/views/market/pages/MarketContractByMarketListPage'),
    roles: [UserRole.MarketEmployee, UserRole.Director, UserRole.MarketSupport, UserRole.CommitteeEmployee]
  },
  {
    path: '/contracts/market',
    component: () => import('src/views/market/pages/MarketContractListPage'),
    roles: [UserRole.MarketEmployee, UserRole.Director, UserRole.MarketSupport, UserRole.CommitteeEmployee]
  },

  {
    path: '/document-templates',
    component: () => import('src/views/documentTemplate/DocumentTemplatePage'),
    roles: [UserRole.Administrator]
  },

  //исследование потребительской продукции
  {
    path: '/appeals/add',
    component: () => import('src/views/inspection/appeal/AppealWizardFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: '/appeals',
    component: () => import('src/views/inspection/appeal/AppealListPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/appeals/:appealId',
    component: () => import('src/views/inspection/appeal/AppealInfoPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/appeals/:appealId/edit',
    component: () => import('src/views/inspection/appeal/AppealWizardFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: '/appeals/:appealId/refferal',
    component: () => import('src/views/inspection/refferal/RefferalWizardFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: '/appeals/:appealId/changelog',
    component: () => import('src/views/inspection/appeal/AppealChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/appeals/:appealId/product/:productId/changelog',
    component: () => import('src/views/inspection/appeal/AppealProductChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/appeals/:appealId/applicant/:applicantId/changelog',
    component: () => import('src/views/inspection/appeal/AppealApplicantChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/appeals/:appealId/conclusion/:conclusionId/changelog',
    component: () => import('src/views/inspection/inspectionConclusion/InspectionConclusionChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/agreements/add',
    component: () => import('src/views/inspection/tradeAgreement/TradeAgreementFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: '/agreements',
    component: () => import('src/views/inspection/tradeAgreement/TradeAgreementListPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/agreements/:agreementId',
    component: () => import('src/views/inspection/tradeAgreement/TradeAgreementInfoPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  },
  {
    path: '/agreements/:agreementId/edit',
    component: () => import('src/views/inspection/tradeAgreement/TradeAgreementFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: '/agreements/:agreementId/changelog',
    component: () => import('src/views/inspection/tradeAgreement/TradeAgreementChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.CommitteeEmployee]
  }, 
  {
    path: '/refferals',
    component: () => import('src/views/inspection/refferal/RefferalListPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/refferals/:refferalId',
    component: () => import('src/views/inspection/refferal/RefferalInfoPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/refferals/:refferalId/edit',
    component: () => import('src/views/inspection/refferal/RefferalWizardFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: '/refferals/:refferalId/changelog',
    component: () => import('src/views/inspection/refferal/RefferalChangeLog'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/refferals/:refferalId/act/add',
    component: () => import('src/views/inspection/refferal/InspectionActFormPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: '/refferals/:refferalId/act/edit',
    component: () => import('src/views/inspection/refferal/InspectionActFormPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'refferals/:refferalId/samples/add',
    component: () => import('src/views/inspection/productSample/SampleWizardFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId',
    component: () => import('src/views/inspection/productSample/SampleInfoPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId/edit',
    component: () => import('src/views/inspection/productSample/SampleWizardFormPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId/changelog',
    component: () => import('src/views/inspection/productSample/SampleChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId/protocol/create',
    component: () => import('src/views/inspection/testProtocol/TestProtocolWizardFormPage'),
    roles: [UserRole.LaboratoryAssistant]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId/protocol/:protocolId/changelog',
    component: () => import('src/views/inspection/testProtocol/TestProtocolChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId/protocol/edit',
    component: () => import('src/views/inspection/testProtocol/TestProtocolWizardFormPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'refferals/:refferalId/samples/:sampleId/protocol/indicators/:indicatorId/changelog',
    component: () => import('src/views/inspection/productSample/TestIndicatorChangeLogPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'refferals/:refferalId/conclusion/create',
    component: () => import('src/views/inspection/inspectionConclusion/InspectionConclusionFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: 'refferals/:refferalId/conclusion/edit',
    component: () => import('src/views/inspection/inspectionConclusion/InspectionConclusionFormPage'),
    roles: [UserRole.Inspector]
  },
  {
    path: 'samples',
    component: () => import('src/views/inspection/productSample/SampleListPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },
  {
    path: 'history',
    component: () => import('src/views/inspection/history/HistoryPage'),
    roles: [UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]
  },

  //для ТЗ
  {
    path: '/sellers',
    component: () => import('src/views/market/seller/SellerListPage'),
    roles: [UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee, UserRole.Entrepreneur]
  },
  {
    path: '/sellers/add',
    component: () => import('src/views/market/seller/SellerFormPage'),
    roles: [UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.Entrepreneur]
  },
  {
    path: '/sellers/:sellerId/edit',
    component: () => import('src/views/market/seller/SellerFormPage'),
    roles: [UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.Entrepreneur]
  },
  {
    path: '/sellers/:sellerId/changelog',
    component: () => import('src/views/market/seller/SellerChangelogPage'),
    roles: [UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]
  },

  //Отчеты
  {
    path: '/reports',
    component: () => import('src/views/report/ReportPage'),
    roles: [UserRole.Administrator, UserRole.CommitteeEmployee]
  },
]

const publicRoutes = [
  {
    path: '/registration',
    component: () => import('src/views/auth/registration/RegistrationWizardPage'),
  },
  {
    path: '/signin',
    component: () => import('src/views/auth/SigninPage'),
  },
  {
    path: '/administrator/login',
    component: () => import('src/views/auth/AdminSigninPage'),
  },
  {
    path: '/privacy',
    component: () => import('src/views/auth/PrivacyPage'),
  },
]

const simpleRoutes = [
  {
    path: '/403',
    component: () =>
      import('src/components/errors/Error403Page'),
  },
  {
    path: '/500',
    component: () =>
      import('src/components/errors/Error500Page'),
  },
  {
    path: '/404',
    component: () =>
      import('src/components/errors/Error404Page'),
  },
  {
    path: '*',
    component: () =>
      import('src/components/errors/Error404Page'),
  },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  privateRoutes,
  publicRoutes,
  simpleRoutes,
};