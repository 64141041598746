import createRootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { api } from 'src/api/baseRtk.api';

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: createRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch