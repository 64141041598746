export default {}

export type AgGridGetResponse<T> = { rows: T[], count: number }

export type AgGridInfiniteGetResponse<T> = { data: T[], total: number }

export type FilterParams = Record<string, any>

export type AddRequest<T> = Partial<T> & Omit<T, 'id'>

export type UpdateRequest<T, K = 'id'> = Partial<T> & Pick<Required<T>, K extends keyof T ? K : never>

export type FormProps<M = any> = { model?: M | undefined }

export type AgGridRequest<T = {}> = { page: number } & T

export type CamelToSnakeCase<S extends string> =
    S extends `${infer T}${infer U}` ?
    `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}` :
    S

export type SnakeToCamelCase<S extends string> =
    S extends `${infer T}_${infer U}` ?
    `${T}${Capitalize<SnakeToCamelCase<U>>}` :
    S

export type SnakeToCamelCaseNested<T> = T extends object ? {
    [K in keyof T as SnakeToCamelCase<K & string>]: SnakeToCamelCaseNested<T[K]>
} : T

export type CamelCaseToSnakeNested<T> = T extends object ? {
    [K in keyof T as CamelToSnakeCase<K & string>]: CamelCaseToSnakeNested<T[K]>
} : T