import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Sidebar from './Sidebar'
import { useState } from 'react'

const drawerWidth = 250

const SidebarMobile = () => {
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <Box>
            <Box
			sx={{ 
				position: 'fixed', 
				top: '12px', 
				left: '8px', 
				zIndex: 50,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
				<IconButton
					color="inherit"
					sx={{ p: 0 }}
					onClick={handleDrawerToggle}>
					<MenuIcon />
				</IconButton>
			</Box>
            <Drawer
				anchor="left"
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				sx={{
					'& .MuiDrawer-paper': {
						width: drawerWidth
					}
				}}>
				<Sidebar drawerWidth={drawerWidth} />
			</Drawer>
        </Box>
    )
}

export default SidebarMobile