import { DateTime } from "luxon"

type CheckFairApplicationCreation = {
    startDate: string, //'2020-01-02'
    endDate: string, //'2020-01-02'
    startTime?: string //'10:00'
    endTime?: string //'10:00'
    currentDate?: DateTime
}

export const checkFairApplicationCreation = ({ startDate, endDate, startTime, endTime, currentDate }: CheckFairApplicationCreation) => {
    const initialDateTime = currentDate ?? DateTime.now()
    
    if (!initialDateTime.isValid) return false
    
    const currentDateTime = initialDateTime.set({
        second: 0,
        millisecond: 0,
    });

    const startDateTime = DateTime.fromISO(startDate).set({
        hour: startTime ? +startTime.split(':')[0] : 0,
        minute: startTime ? +startTime.split(':')[1] : 0,
        second: 0,
        millisecond: 0,
    })

    const endDateTime = DateTime.fromISO(endDate).set({
        hour: endTime ? +endTime.split(':')[0] : 23,
        minute: endTime ? +endTime.split(':')[1] : 59,
        second: 59,
        millisecond: 0,
    })

    if (currentDateTime < startDateTime || currentDateTime > endDateTime){
        return false
    }

    return true
}