import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLanguageCode } from "src/modules/i18n";

const initialState = {
    menuVisible: true,
    language: getLanguageCode(),
    loading: false,
    menuItemsVisible: {} as Record<string, boolean>,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleMenuItems(state, action: PayloadAction<string>) {
            const group = action.payload;
            state.menuItemsVisible[group] = !state.menuItemsVisible[group]
        },
        toggleMenu(state) {
            state.menuVisible = !state.menuVisible
        },
        showMenu(state) {
            state.menuVisible = true;
        },
        hideMenu(state) {
            state.menuVisible = false;
        }
    }
})

export const { toggleMenuItems, toggleMenu, showMenu, hideMenu } = layoutSlice.actions
export default layoutSlice.reducer