import { useMemo } from 'react'
import {
    UserPermission,
    UserRole,
} from 'src/config/rolesPermissionsMap'
import { useAuth } from './useAuth'

/** Параметры хука пользовательских разрешений */
export interface UseAccessControlProps {
    /**
     * @param {UserRole[]} roles - Список пользовательских ролей
     * @param {UserPermission[]} permissions - Список пользовательских прав
     */

    roles?: UserRole[]
    permissions?: UserPermission[]
}

export interface UseAccessControlReturn {
    isAdmin: boolean
    isAllowed: boolean
    hasPermissions: (permissions: UserPermission[]) => boolean
    hasRoles: (roles: UserRole[]) => boolean
}

/**
 *
 * @param props - Параметры хука пользовательских разрешений
 * @returns Объект с набором методов и свойств для работы с системой разрешений
 * @example
 * Пример использования хука
 * ```
 * const { isAllowed } = useUserPermissions({
 *  roles: ['ADMIN'],
 *  permissions: ['DELETE_ROWS']
 * })
 * ```
 */

export const useAccessControl = (
    props?: UseAccessControlProps
): UseAccessControlReturn => {
    const { user } = useAuth()

    const hasRoles = (roles: UserRole[]): boolean => {
        if (user?.role === UserRole.Administrator) return true;
        if (user && roles.length > 0) return roles.some(role => role === user.role)
        return true
    }

    const hasPermissions = (permissions: UserPermission[]): boolean => {
        // TODO: реализовать для прав
        // if (user && user?.role in rolesPermissionsMap) {
        //     return permissions.every((permission) =>
        //         rolesPermissionsMap[user?.role].includes(permission)
        //     )
        // }
        return true
    }

    const isAllowed = useMemo(() => {
        return hasRoles(props?.roles ?? [])
        // && hasPermissions(props?.permissions ?? []);
    }, [props])

    const isAdmin = useMemo(() => {
        return hasRoles([UserRole.Administrator])
    }, [user])

    return {
        isAdmin,
        isAllowed,
        hasRoles,
        hasPermissions,
    }
}
