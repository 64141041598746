import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({ showSpinner: false });

export default class ProgressBar {
  static start() {
    NProgress.start();
  }

  static isStarted() {
    return NProgress.isStarted();
  }

  static done() {
    NProgress.done();
  }
}
