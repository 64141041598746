import React from 'react';
import LoadingComponent from './Loading';

const withLazyLoad = (component: () => Promise<any>) => {
    const LazyComponent = React.lazy(component);

    return (): React.ReactNode => (
        <React.Suspense fallback={<LoadingComponent />}>
            <LazyComponent />
        </React.Suspense>
    );
}

export default withLazyLoad;