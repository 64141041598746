import * as React from 'react';
import { useEffect } from 'react';
import ProgressBar from '../ProgressBar';
import { useAppSelector } from 'src/store';
import layoutSelectors from 'src/store/layout/layoutSelectors';

export const useProgressBar = () => {
    const isLayoutLoading = useAppSelector(layoutSelectors.selectLoading);
    const isLoading = isLayoutLoading;

    useEffect(() => {
        ProgressBar.start();
        return () => {
            ProgressBar.done();
        }
    }, [isLoading]);

    return isLoading
}