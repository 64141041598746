let memoizedToken: string | null = null;

export class AccessTokenStorage {
  static get() {
    return (
      memoizedToken ?? localStorage.getItem('accessToken')
    );
  }

  static set(token: string | null, rememberMe: boolean) {
    if (!token) return;
    if (rememberMe) {
      localStorage.setItem('accessToken', token);
    } else {
      memoizedToken = token;
    }
  }

  static clear() {
    localStorage.removeItem('accessToken')
    memoizedToken = null;
  }
}
