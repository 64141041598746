export const phoneMask = '+7(999)999-99-99'

export const timeMask = '99:99'
export const timePeriodMask = 'с 99:99 по 99:99'
export const dateMask = '99.99.9999'
export const dateWithTimeMask = '99.99.9999 99:99'

export const passportSeriesMask = '9999'
export const passportNumberMask = '999999'
export const passportAuthorityCode = '999-999'

export const kppMask = "999999999"//9
export const entityInnMask = "9999999999"//10
export const innMask = "999999999999"//12
export const ogrnMask = "9999999999999"//13
export const ogrnipMask = "999999999999999"//15

export const addressPlaceholder = "Московская область, Мытищинский район, город Мытищи, улица Ульяновская, дом 3"
export const citizenshipPlaceholder = "Российская Федерация"
