import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'src/modules/i18n/i18n';
import { store } from './store';
import { injectStoreToAxios } from './modules/axios/axiosInterceptors';
import App from './App';

injectStoreToAxios(store)

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./modules/mocks/browser')
  // worker.start()
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();