import { rest } from 'msw'

export const notificationHandlers = [
    rest.get('http://reestr61.local/api/notifications?skip=0&limit=0', (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json([
                {
                    id: '1',
                    isRead: false
                },
            ])
        )
    }),
]

