export enum FileType {
    DOC = "doc",
    DOCX = "docx",
    PPT = "ppt",
    PPTX = "pptx",
    TXT = "txt",
    XLS = "xls",
    XLSX = "xlsx",
    PDF = "pdf",
    PNG = "png",
    JPG = "jpg",
    JPEG = "jpeg"
}
export const defaultFileTypes = [
    FileType.DOC, 
    FileType.DOCX, 
    FileType.TXT, 
    FileType.PDF, 
    FileType.PNG, 
    FileType.JPG, 
    FileType.JPEG
];
export const allFileTypes = [
    FileType.DOC, 
    FileType.DOCX, 
    FileType.PPT, 
    FileType.PPTX, 
    FileType.TXT,
    FileType.XLS, 
    FileType.XLSX, 
    FileType.PDF, 
    FileType.PNG,
    FileType.JPG, 
    FileType.JPEG
]