import { Badge, ListItemText } from '@mui/material'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/api/auth/hooks/useAuth'
import { useGetActiveNotificationsQuery } from 'src/api/notification/notifications.api'
import { UserRole } from 'src/config/rolesPermissionsMap'
import Menu from './Menu'
import MenuItem from './MenuItem'

const NotificationsMenuItem = ({roles}: {roles?: UserRole[]}) => {
    const { t } = useTranslation('common', { keyPrefix: 'menus' })
    const { user } = useAuth()
    const { data: notifications } = useGetActiveNotificationsQuery(user?.role ?? skipToken)

    return <MenuItem label={t('notifications')} roles={roles} component={<>
        <ListItemText primary={t('notifications')} />
        <Badge color="secondary" badgeContent={notifications?.notRead ?? 0} invisible={notifications?.notRead === 0} sx={{mr: 1.5}}/>
    </>} url="/notifications" />
}

const SideNavigation = () => {
    const { t } = useTranslation('common', { keyPrefix: 'menus' })

    return (
        <Menu>
            <MenuItem label={t('userProfile')}>
                <Menu depth={1}>
                    <MenuItem label={t('userProfile')} url="/profile" />
                    <NotificationsMenuItem roles={[
                        UserRole.Individual, 
                        UserRole.Entity, 
                        UserRole.Entrepreneur,
                        UserRole.MarketEmployee, 
                        UserRole.MarketSupport, 
                        UserRole.Director, 
                        UserRole.FairEmployee,
                        UserRole.Inspector, 
                        UserRole.LaboratoryAssistant
                    ]}/>
                </Menu>
            </MenuItem>

            <MenuItem
                label={t('documentTemplate')}
                url="/document-templates"
                roles={[UserRole.Administrator]}
            />

            <MenuItem label={t('users')} roles={[UserRole.Administrator]}>
                <Menu depth={1}>
                    <MenuItem
                        label={t('applicants')}
                        url="/users/applicants"
                    />
                    <MenuItem
                        label={t('employees')}
                        url="/users/employees"
                    />
                </Menu>
            </MenuItem>

            <MenuItem label={t('fairs')} roles={[UserRole.FairEmployee, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}>
                <Menu depth={1}>
                    <MenuItem
                        label={t('addFair')}
                        url="/fairs/add"
                        roles={[UserRole.FairEmployee]}
                    />
                    <MenuItem
                        label={t('fairsList')}
                        url="/fairs"
                        roles={[UserRole.FairEmployee, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('upcomingFairs')}
                        url="/fairs"
                        roles={[UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}
                        excludeAdmin
                    />
                    <MenuItem
                        label={t('applicationList')}
                        url="/applications/fair"
                        roles={[UserRole.FairEmployee, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('userFairApplications')}
                        url="/applications/fair"
                        roles={[UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}
                        excludeAdmin
                    />
                    <MenuItem
                        label={t('contracts')}
                        url="/contracts/fair"
                        roles={[UserRole.FairEmployee, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('information')}
                        url="/information/fairs"
                    />
                </Menu>
            </MenuItem>

            <MenuItem label={t('markets')} roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee, UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}>
                <Menu depth={1}>
                    <MenuItem
                        label={t('addMarket')}
                        url="/markets/add"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director]}
                    />
                    <MenuItem
                        label={t('marketsList')}
                        url="/markets"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('markets')}
                        url="/markets"
                        roles={[UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}
                        excludeAdmin
                    />
                    <MenuItem
                        label={t('marketApplicationList')}
                        url="/applications/market"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('userMarketApplications')}
                        url="/applications/market"
                        roles={[UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}
                        excludeAdmin
                    />
                    <MenuItem
                        label={t('myDocuments')}
                        url="/markets/documents"
                        roles={[UserRole.Individual, UserRole.Entity, UserRole.Entrepreneur]}
                        excludeAdmin
                    />
                    <MenuItem
                        label={t('contractsList')}
                        url="/contracts/market"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]}
                    />

                    {/* market ids - from seeds */}
                    <MenuItem
                        label={`${t('contractsList')}: Гражданский`}
                        url="/contracts/market/by-market/3"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={`${t('contractsList')}: Хасанский`}
                        url="/contracts/market/by-market/1"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={`${t('contractsList')}: Кузнечный`}
                        url="/contracts/market/by-market/2"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee]}
                    />
                    {/*  */}

                    <MenuItem
                        label='Журнал заявителей'
                        url="/users/markets/applicants"
                        roles={[UserRole.Director, UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label='Журнал продавцов'
                        url="/sellers"
                        roles={[UserRole.MarketEmployee, UserRole.MarketSupport, UserRole.Director, UserRole.CommitteeEmployee, UserRole.Entrepreneur]}
                    />
                    <MenuItem
                        label={t('information')}
                        url="/information/markets"
                    />
                </Menu>
            </MenuItem>

            <MenuItem label={t('inspection')} roles={[UserRole.Inspector, UserRole.LaboratoryAssistant, UserRole.CommitteeEmployee]}>
                <Menu depth={1}>
                    <MenuItem
                        label={t('appeals')}
                        url="/appeals"
                        roles={[UserRole.Inspector, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('refferals')}
                        url="/refferals"
                    />
                    <MenuItem
                        label={t('samples')}
                        url="/samples"
                    />
                    <MenuItem
                        label={t('agreements')}
                        url="/agreements"
                        roles={[UserRole.Inspector, UserRole.CommitteeEmployee]}
                    />
                    <MenuItem
                        label={t('labEmployees')}
                        url="/users/inspectors"
                    />
                    {/* <MenuItem
                        label={t('history')}
                        url="/history"
                    /> */}
                </Menu>
            </MenuItem>

            <MenuItem
                label={t('reports')}
                url="/reports"
                roles={[UserRole.Administrator, UserRole.CommitteeEmployee]}
            />
        </Menu>
    )
}

export default SideNavigation;