import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'src/modules/axios/axiosBaseQuery'

/* const baseQueryWithRetry = retry(axiosBaseQuery(), { maxRetries: 1 })
 */
export const api = createApi({
    reducerPath: 'splitApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        'User',
        'Fairs',
        'FairApplication',
        'FairContract',
        'Markets',
        'MarketPlacesTypes',
        'MarketPlaces',
        'MarketApplication',
        'MarketHelpInfo',
        'UserDocument',
        'Notifications',
        'DocumentTemplates',
        'Appeal',
        'ProductSample',
        'Refferal',
        'TradeAgreement',
        'Seller',
    ],
    endpoints: () => ({}),
})