import { api } from '../baseRtk.api'
import { Notification, NotificationList, NotificationType } from 'src/models/notification/notification'
import { UserRole } from 'src/config/rolesPermissionsMap'
import camelcaseKeys from 'camelcase-keys'

export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<NotificationList, { role: UserRole, page: number }>({
      query: ({ page }) => ({
        url: '/notifications',
        method: 'GET',
        params: { page }
      }),
      providesTags: ['Notifications'],
      transformResponse: (notifications: NotificationList, meta, arg) => (
        transformNotificationByRole(notifications, arg.role)
      )
    }),
    getActiveNotifications: build.query<NotificationList, string>({
      query: () => ({
        url: `/notifications?isRead=false`,
        method: 'GET',
      }),
      providesTags: ['Notifications'],
      transformResponse: (notifications: NotificationList, meta, role) => (
        transformNotificationByRole(notifications, role)
      )
    }),
    getNotification: build.query<Notification, string>({
      query: (id) => ({
        url: `/notifications/${id}`,
        method: 'GET',
      }),
    }),
    markAsRead: build.mutation<Notification, string>({
      query: (id) => ({
        url: `/notifications/${id}/markAsRead`,
        method: 'PUT',
      }),
      invalidatesTags: ['Notifications']
    }),
    markAsReadAll: build.mutation<Notification, void>({
      query: () => ({
        url: `notifications/markAsReadAll`,
        method: 'PUT',
      }),
      invalidatesTags: ['Notifications']
    }),
  }),
})

const transformNotificationByRole = (notificationDtos: NotificationList, role: string) => {
  let notifications = notificationDtos.data.map(notificationDto => {
    const notification = camelcaseKeys(notificationDto, { deep: true })
    return {
      ...notification,
      type: transformTypeNotification(notification.type)
    }
  })

  let filteredNotifications = notifications

  switch(role){
    case UserRole.FairEmployee: {
      filteredNotifications = notifications.filter((notification) => notification.type === NotificationType.Fair)
      break
    }
    case UserRole.MarketEmployee: {
      filteredNotifications = notifications.filter((notification) => notification.type === NotificationType.Market)
      break
    }
    // case UserRole.Inspector:
    // case UserRole.LaboratoryAssistant: return notifications.filter((notification) => notification.type.toLowerCase().includes('inspection'))
    default: break
  }

  return {
    ...notificationDtos,
    data: filteredNotifications,
  }
}

const transformTypeNotification = (notificationType: string) => {
  const type = notificationType.toLowerCase();
  if (type.includes('fair')) return NotificationType.Fair
  if (type.includes('market')) return NotificationType.Market
  if (type.includes('inspection')) return NotificationType.Inspection
  return notificationType
}

export const {
  useGetNotificationsQuery,
  useGetNotificationQuery,
  useMarkAsReadMutation,
  useGetActiveNotificationsQuery,
  useMarkAsReadAllMutation
} = notificationsApi
