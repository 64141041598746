import { createSlice } from '@reduxjs/toolkit'
import type { User } from 'src/models/user/user'
import { authApi } from 'src/api/auth/auth.api';
import { usersApi } from 'src/api/users/users.api';
import { AccessTokenStorage } from 'src/api/auth/accessTokenStorage';

export interface AuthSliceState {
    user?: User;
    accessToken: string | null
    expiresIn: number
    isAuthExpired: boolean
    rememberMe: boolean
}

const initialState: AuthSliceState = {
    expiresIn: 0,
    isAuthExpired: false,
    rememberMe: false,
    accessToken: AccessTokenStorage.get()
}



const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => {
            return {
                ...initialState,
                accessToken: null
            }
        },
        setToken: (state, action) => {
            state.accessToken = action.payload
        },
        setRememberMe: (state, action) => {
            state.rememberMe = action.payload
        },
        setIsAuthExpired: (state, action) => {
            state.isAuthExpired = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                state.user = action.payload.user
                state.accessToken = action.payload.accessToken
                state.expiresIn = (action.payload as any).expires_in
                state.isAuthExpired = false
            })
            .addMatcher(usersApi.endpoints.getCurrentUserProfile.matchFulfilled, (state, action) => {
                state.user = action.payload
            })
    }
})

export const {
    logout,
    setToken,
    setRememberMe,
    setIsAuthExpired
} = authSlice.actions
export default authSlice.reducer