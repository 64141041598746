import { useAppDispatch, useAppSelector } from 'src/store';
import { useGetCurrentUserProfileQuery } from 'src/api/users/users.api';
import { logout as doLogout, setRememberMe } from 'src/store/auth/authSlice';
import { useLoginMutation } from 'src/api/auth/auth.api';
import { User } from 'src/models/user/user';
import { AccessTokenStorage } from '../accessTokenStorage';
import { useNavigate } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { api } from 'src/api/baseRtk.api'

export type UserCredentials = { email: string, password: string }

export interface UseAuthReturn {
    user: User | undefined,
    isLoginError: boolean,
    loginError: { status?: number | undefined, data: any} | SerializedError | undefined
    isLoginLoading: boolean,
    isUserProfileLoading: boolean,
    isUserProfileError: boolean,
    isAuthenticated: boolean,
    isAuthExpired: boolean
    methods: {
        login: (credentials: UserCredentials, rememberMe?: boolean) => void,
        logout: () => void
    }
}

export const useAuth = (): UseAuthReturn => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const auth = useAppSelector(state => state.auth);

    const [tryLogin, { isLoading: isLoginLoading, error: loginError }] = useLoginMutation();
    const { data: user, error: profileError } = useGetCurrentUserProfileQuery(undefined, {
        skip: !auth.accessToken
    });

    const login = async (credentials: UserCredentials, rememberMe?: boolean) => {
        try {
            const { accessToken } = await tryLogin(credentials).unwrap();
            AccessTokenStorage.set(accessToken, Boolean(rememberMe));
            dispatch(setRememberMe(Boolean(rememberMe)))
            navigate('/', { replace: true })
        } catch (err) {
            if (err instanceof Error)
                console.error(err.message)
        }
    }

    const logout = () => {
        AccessTokenStorage.clear();
        dispatch(api.util.resetApiState());
        dispatch(doLogout())
    }

    return {
        user,
        isAuthenticated: Boolean(auth.user),
        isLoginLoading,
        isUserProfileLoading: Boolean(auth.accessToken) && !Boolean(auth.user),
        isAuthExpired: auth.isAuthExpired,
        isUserProfileError: Boolean(profileError),
        isLoginError: Boolean(loginError),
        loginError,
        methods: {
            login,
            logout
        }
    }
}
