// Ограничение на срок подачи в 3 месяца от 1 января каждого года на период предоставления торговых мест.
// 1 января - 31 марта
// 1 апреля - 30 июня
// 1 июля - 30 сентября
// 1 октября - 31 декабря

import { DateTime } from "luxon"

export const getLimitedApplicationEndDate = (startDate?: string) => {
    const currentDate = startDate ? DateTime.fromISO(startDate) : DateTime.now()
    const currentYear = currentDate.year

    const borderDates = [
        DateTime.fromObject({ year: currentYear, month: 1, day: 1 }),
        DateTime.fromObject({ year: currentYear, month: 4, day: 1 }),
        DateTime.fromObject({ year: currentYear, month: 7, day: 1 }),
        DateTime.fromObject({ year: currentYear, month: 10, day: 1 }),
        DateTime.fromObject({ year: currentYear + 1, month: 1, day: 1 }),
        DateTime.fromObject({ year: currentYear + 1, month: 4, day: 1 }), //for december dates that are 14 days less than 01.01
    ];

    const currentPeriodEndDate = borderDates.find(el => el > currentDate) ?? DateTime.fromObject({ year: currentYear + 1, month: 1, day: 1})

    const isNextPeriodChoice = startDate ? false : currentPeriodEndDate.minus({ days: 14 }) < currentDate
    const currentPeriodIndex = borderDates.findIndex(el => el === currentPeriodEndDate)

    const nextBorderDate = isNextPeriodChoice ? borderDates[currentPeriodIndex + 1] : currentPeriodEndDate
    return nextBorderDate.minus({ days: 1 }).toISODate()
}