import { createSelector } from 'reselect';
import { RootState } from '../store';

const selectLayout = (state: RootState) => state.layout;

const selectMenuVisible = createSelector(
  [selectLayout],
  (layout) => Boolean(layout.menuVisible),
);

const selectLoading = createSelector(
  [selectLayout],
  (layout) => Boolean(layout.loading),
);

const selectLanguage = createSelector(
  [selectLayout],
  (layout) => layout.language,
);

const selectMenuItemsVisible = createSelector(
  [selectLayout],
  (layout) => layout.menuItemsVisible,
);

const layoutSelectors = {
  selectLayout,
  selectMenuVisible,
  selectLoading,
  selectLanguage,
  selectMenuItemsVisible,
};

export default layoutSelectors;
