import Axios from 'axios';
import config from 'src/config';
import Qs from 'qs';

const axios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer: (params: Record<string, any>) => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (value instanceof Date) {
          return value.toISOString();
        }
        return value;
      },
    });
  },
});


export default axios;
