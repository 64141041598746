import { DateTime } from 'luxon'

export const toLuxon = (date: string) => {
    try {
        return DateTime.fromISO(date).setLocale('ru')
    } catch (err) {
        console.error('Ошибка преобразования даты')
    }
    return DateTime.now().setLocale('ru')
}

export const formatDate = (date: string) => {
    return toLuxon(date).toFormat('ff')
}
export const formatLongDate = (date?: string) => {
    if (!date || !DateTime.fromFormat(date, 'yyyy-MM-dd').isValid) return
    return toLuxon(date).toFormat('d MMMM yyyy')
}
export const formatLocaleDate = (date: string) => {
    return toLuxon(date).toFormat('dd.MM.yyyy')
}

export const checkCurrentDateWithEndDate = (date: string) => {
    const currentDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const acceptingApplicationsEndDate = DateTime.fromISO(date).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    })

    return acceptingApplicationsEndDate >= currentDate
}

export const getCorrectDate = (date?: string, emptyValueText?: string) => {
    if (!date) return emptyValueText ?? 'Не заполнено'
    return DateTime.fromISO(date).isValid ? formatLocaleDate(date) : date
}

export const findDaysCountBetweenDates = ({ startDate, endDate }: { startDate: string, endDate: string }): number | undefined => {

    const startDateTime = DateTime.fromISO(startDate);
    const endDateTime = DateTime.fromISO(endDate);

    if (!startDateTime.isValid || !endDateTime.isValid) return

    return endDateTime.diff(startDateTime, 'days').toObject().days;
}