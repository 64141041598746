import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query'
import { AxiosRequestConfig, AxiosError } from 'axios'
import SnackbarUtil from 'src/utils/notistack/snackbar.util';
import axios from './axios';

export interface AxiosBaseError {
    status?: number,
    data: any
}

export const axiosBaseQuery = (): BaseQueryFn<
    {
        url: string
        method?: AxiosRequestConfig['method']
        body?: AxiosRequestConfig['data']
        params?: any
    },
    unknown,
    AxiosBaseError
> =>
    async ({ url, method = 'GET', body, params }) => {
        try {
            const result = await axios({ url, method, data: body, params })
            return result
        } catch (axiosError) {
            let err = axiosError as AxiosError
            // if (err.code !== AxiosError.ERR_CANCELED && (err.response?.data as any).message)
            //     SnackbarUtil.error((err.response?.data as any).message);
            // if (err.code !== AxiosError.ERR_CANCELED)
            //     SnackbarUtil.error(err.message);
            return {
                error: { status: err.response?.status, data: err.response?.data },
            }
        }
    }