import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/api/auth/hooks/useAuth';

interface PublicRouteProps {
  children: any
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/" replace />
  }
  return children;
}

export default PublicRoute;
