import { Box, Stack, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'
import Sidebar from './sidebar/Sidebar'
import SidebarMobile from './sidebar/SidebarMobile'
import Worms from 'src/assets/worms.svg'
import { Footer } from './Footer'

interface LayoutProps {
    children: React.ReactNode
}

const drawerWidth = 250

const Layout = (props: LayoutProps) => {
    const theme = useTheme()
    const breakpoint = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Box
            sx={{
                backgroundColor: '#f0f2f5',
                display: 'flex',
            }}>
            {breakpoint ? (
                <Sidebar drawerWidth={drawerWidth} />
            ) : (
                <SidebarMobile />
            )}
            <Box
                sx={{
                    flexGrow: 1,
                    minHeight: '100vh',
                    marginLeft: breakpoint ? `${drawerWidth}px` : 0,
                    overflowX: 'hidden',
                    position: 'relative',
                    '&::before': {
                        backgroundImage: `url(${Worms})`,
                        backgroundRepeat: 'repeat-x',
                        opacity: '0.3',
                        position: 'absolute',
                        content: '""',
                        top: '0',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        pointerEvents: 'none'
                    }
                }}>
                
				<Stack 
                    justifyContent='space-between' 
                    spacing={2}
                    sx={{ minHeight: '100%' }}>
					<Box
						padding={2}
                        pl={ breakpoint ? 2 : 5 }
						sx={{
                            position: 'relative'
                        }}>
						{props.children}
					</Box>

                    <Footer />
				</Stack>
            </Box>
        </Box>
    )
}

export default Layout