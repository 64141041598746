import { AxiosError, AxiosRequestConfig } from 'axios';
import { getLanguageCode } from 'src/modules/i18n';
import SnackbarUtil from 'src/utils/notistack/snackbar.util';
import { Store } from 'redux';
import axios from './axios';
import { logout, setIsAuthExpired, setToken } from 'src/store/auth/authSlice';
import { AccessTokenStorage } from 'src/api/auth/accessTokenStorage';
import { history } from 'src/components/routing/browserHistory';

let store: Store
let isRefreshing = false;
const failedQueue = new Set();


export const injectStoreToAxios = (_store: Store) => {
    store = _store
}

const processQueue = (failedQueue: any, error = null) => {
    try {
      for (const promise of failedQueue) {
        if (error) {
          promise.reject(error);
        } else {
          promise.resolve(promise.config);
        }
      }
    } catch (error) {}
  };


axios.interceptors.response.use(
    (response) =>  response, 
    (error: AxiosError) => {
        if (error.response?.status === 401 && !isRefreshing) {
            isRefreshing = true;
            return axios
            .post('auth/refresh')
            .then(async (response) => {
                AccessTokenStorage.set(response.data.accessToken, true)
                store.dispatch(setToken(response.data.accessToken))
                processQueue(failedQueue, null)
                return axios(error.config)
            })
            .catch((error) => {
                processQueue(failedQueue, error);
                store.dispatch(logout())
                store.dispatch(setIsAuthExpired(true))
                AccessTokenStorage.clear();
                return Promise.reject(error);
            })
            .finally(() => {
                isRefreshing = false
            })
        } else if(isRefreshing && error.response?.status === 401){
            const config = error.config;
            return new Promise((resolve, reject) => {
                failedQueue.add({ resolve, reject, config });
              })
                .then((request) => {
                  return axios(request as AxiosRequestConfig);
                })
                .catch((error) => {
                  return Promise.reject(error);
                });
        } 
        if (error.response?.status === 404) {
              history.replace('/404')
        }
        return Promise.reject(error);

})

axios.interceptors.request.use(async (request) => {
    const state = store.getState();

    const token = state.auth.accessToken;

    if (request.headers) {
        if (token) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        request.headers['Accept-Language'] = getLanguageCode();
    }

    return request;
}, (err) => {
    console.log(err)
    SnackbarUtil.error(err.message);
    return Promise.reject(err);
},
);
