import { Route, Routes } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import routes from './routes'
import withLazyLoad from './withLazyLoad'
import { useProgressBar } from './hooks/useProgressBar'

const RoutesBuilder = () => {
    useProgressBar()

    return (
            <Routes>
                {routes.simpleRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={withLazyLoad(route.component)()}
                    />
                ))}

                {routes.publicRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            <PublicRoute>
                                {withLazyLoad(route.component)()}
                            </PublicRoute>
                        }
                    />
                ))}

                {routes.privateRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            <PrivateRoute
                                roles={route.roles}
                            >
                                {withLazyLoad(route.component)()}
                            </PrivateRoute>
                        }
                    />
                ))}
            </Routes>
    )
}

export default RoutesBuilder
