const fair = {
    fairForm: {
        number: '№',
        index: '№ п/п',
        theme: 'Тематика ярмарки',
        address: 'Адрес ярмарки',
        type: 'Вид ярмарки',
        priorityTradingPlace: 'Приоритетное торговое место',
        additionalTradingPlace: 'Дополнительное торговое место',
        name: 'Название ярмарки',
        fairProvidedStrategicDocuments: 'Ярмарка, предусмотренная документами стратегического планирования',
        organizer: 'Организатор ярмарки',
        geoInfoApplicationNumber: 'Номер заявки в региональной информационной системе «Геоинформационная система Санкт Петербурга»',//'Номер заявки в региональной информационной системе, содержащей сведения об объектах недвижимости и объектах землеустройства «Геоинформационная система Санкт Петербурга»'
        area: 'Площадь земель или земельного участка, необходимая для проведения ярмарки (кв.м)',
        startDate: 'Дата начала проведения ярмарки',
        endDate: 'Дата окончания проведения ярмарки',
        workingTime: 'Режим работы ярмарки',
        productGroup: 'Товарная группа',
        rangeOfGoodsFair: 'Ассортимент реализуемых товаров на ярмарке',
        schemeTradingPlaces: 'Схема размещения торговых мест',
        acceptingApplicationsStartDate: 'Дата начала приёма заявок',
        acceptingApplicationsEndDate: 'Дата окончания приёма заявок',
        acceptingApplicationsTime: 'Время приема заявок',
        acceptingApplicationsStartTime: 'Время начала приёма заявок',
        acceptingApplicationsEndTime: 'Время окончания приёма заявок',
        acceptingPaperApplicationsStartDate: 'Дата начала приёма заявок',
        acceptingPaperApplicationsStartTime: 'Время начала приёма заявок',
        acceptingPaperApplicationsEndDate: 'Дата окончания приёма заявок',
        acceptingPaperApplicationsEndTime: 'Время окончания приёма заявок',
        acceptingApplicationsComment: 'Комментарий к приему заявок',
        website: 'Адрес официального сайта организатора ярмарки',
        applicationsConsiderationAddress: 'Адрес рассмотрения заявок',
        applicationsConsiderationDates: 'Даты рассмотрения заявок',
        conclusionContractsAddress: 'Адрес заключения договоров',
        conclusionContractsDates: 'Даты заключения договоров ',
        conclusionContractsTime: 'Время заключения договоров',
        deadlineAcceptingApplications: 'Срок приёма заявок на предоставление торговых мест на ярмарке',
    },
    application: {
        number: '№ п/п',
        status: 'Статус заявки',
        createdAt: 'Дата создания заявки',
        registrationNumber: 'Регистрационный номер',
        applicant: 'Заявитель',
        rejectReason: 'Причина отказа',
        pointTradingPlace: 'Пункт порядка предоставления торговых мест',
        place: 'Номер места',
        applicationReceived: 'Заявка получена',
        timeApplication: 'Время поступления заявки',
        contractNumber: 'Номер договора',
        rejectComment: 'Комментарий к отзыву заявки',
        tradingPlaceNumber: 'Номер предоставленного места',
        sector: 'Товарная группа',
        legalForm: 'Организационно-правовая форма',
        receiptType: {
            byPaper: 'Бумажный',
            byEmail: 'Почта',
            bySystem: 'Система'      
        },
    },
    grades: {
        confirmDocs: 'Оценка опыта участия в общегородских, районных или муниципальных ярмарках, выставках, фестивалях и иных культурно-массовых мероприятиях, включавших в себя продажу товаров (выполнение работ, оказание услуг), за последние пять лет',//, укажите количество подтверждающих документов
        assessmentGradeDocs: 'Оценка наличия ассортиментного перечня продукции, предлагаемой к реализации на ярмарке, и его соответствия ассортиментному перечню продукции, установленного организатором ярмарки для данного торгового места, цены за единицу продукции и наличия товаров низкой ценовой категории (до 100 рублей), информация об ассортиментном перечне продукции',
        sellerOutfitDocs: 'Информация о форменной одежде продавцов',
        brandInfoDocs: 'Информация о брендированной продукции для оценки наличия опыта и готовности по выпуску брендированной продкции в соотвествии с тематикой мероприятия',
        brandGradeDocs: 'Оценка наличия разработанной концепции организации торгового (рабочего) места, включая оформление упаковки продукции, прейскуранта, меню (при наличии), с учетом тематики ярмарки, информация о концепции',
        presentationDocs: 'Презентация участника отбора, описывающая его деятельность, производимые товары или услуги и их соответсвие с тематикой ярмарки, планируемой организатором ярмарки',
        productionGradeDocs: 'Оценка наличия документов, подтверждающих, что участник отбора является производителем продукции и товаров, реализуемых на ярмарке',//, указать наличие документов
        practiceGradeDocs: 'Оценка наличия документов, выданных в установленном порядке представителем субъекта Российской Федерации или иностранного государства, подтверждающих полномочия участника отбора представлять регион или страну',    
    },
    protocol: {
        votesFor: 'Проголосовали за',
        votesAgainst: 'Проголосовали против',
        votesAbstained: 'Воздержались',
        votingType: {
            allApps: 'Голосование за количество зарегистрированных заявок на ярмарку',
            allAcceptedApps: 'Поступившие заявки, которым предоставлены торговые места',
            allRejectedApps: 'Поступившие заявки, которые получили статус "Отказано"',
            reassignedApps: 'Поступившие заявки, которым предоставлено торговое место на другой товарной группе',
            waitingApps: 'Поступившие заявки, по которым принято решение о включении в реестр «ожидание»',
            strategicIsAdditionalAcceptance: 'Решение о продлении сроков приема заявок',
            regionalIsAdditionalAcceptance: 'Решение о продлении сроков приема заявок',
        }
    },
}

export default fair