import { User } from 'src/models/user/user';
import { api } from '../baseRtk.api'
import { AddRequest } from 'src/config/globalConfig';

export type Credentials = { email: string, password: string, isAdminLogin?: boolean };
export type AuthResponse = { expiresIn: number; refreshToken: string; accessToken: string; tokenType: string, user: User }

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<AuthResponse, Credentials>({
            query: (credentials) => ({
                url: credentials.isAdminLogin ? 'administrator/login' : 'auth/login',
                method: 'POST',
                body: credentials,
            }),
            extraOptions: {
                maxRetries: 0
            },
            invalidatesTags: ['User']
        }),
        signup: build.mutation<{ message: string }, AddRequest<User>>({
            query: (user) => ({
                url: 'auth/registration',
                method: 'POST',
                body: user
            }),
        }),
    }),
})

export const {
    useLoginMutation,
    useSignupMutation
} = authApi

export const {
    endpoints: { login },
} = authApi
