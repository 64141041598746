import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common from './locales/ru/common';
import fair from './locales/ru/fair';
import inspection from "./locales/ru/inspection";
import market from "./locales/ru/market";

const resources = {
  ru: {
    common,
    fair,
    inspection,
    market
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    defaultNS: 'common',
    ns: ['common', 'fair', 'inspection', 'market'],
    lng: getLanguageCode(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export function setLanguageCode(language: string): void {
  localStorage.setItem('lang', language);
}

export function getLanguageCode(): string {
  return localStorage.getItem('lang') ?? 'ru'
}

export default i18n;