import { Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from "src/assets/logo.svg";
import { ReactComponent as EmblemSidebar } from "src/assets/emblemSidebar.svg";
import SideNavigation from '../menu/SideNavigation';

const Sidebar = ({ drawerWidth } : { drawerWidth: string | number}) => {
    return (
        <Paper 
        square
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10,
            width: drawerWidth,
            flexShrink: 0,
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
            overflowY: 'scroll',
            height: '100vh',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '2px'
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgba(170,163,163,0.8)'
            }
        }}>
            <Link to="/">
                <Box p={'1rem 1rem 0 1rem'}>
                    <Logo style={{ 'width': '100%' }} />
                </Box>
            </Link>

            <SideNavigation />
            
            <Box p={'2rem 1rem 0 1rem'}>
                <EmblemSidebar style={{ 'width': '100%' }} />
            </Box>
        </Paper>
    )
}

export default Sidebar