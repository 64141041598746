
export const foodProductOptions = [
    'мясо и мясопродукты, яйцо',
    'молоко, молочные продукты',
    'рыба, рыбные продукты и другие гидробионты',
    'алкогольная продукция',
    'плодоовощная продукция и продукты ее переработки',
    'детское питание',
    'зерно, мукомольно-крупяные и хлебобулочные изделия',
    'масложировая продукция',
    'вода питьевая, вода минеральная',
    'сахар, мед, кондитерские изделия',
    'вкусовые товары',
    'продукция общественного питания',
    'кулинарная продукция',
    'прочие'
]

export const nonFoodProductOptions = [
    'электробытовые товары',
    'парфюмерно-косметические товары',
    'обувь',
    'галантерейные товары',
    'строительные и сантехнические товары',
    'мебельные товары',
    'компьютерная техника',
    'текстильные, швейные трикотажные товары',
    'ювелирные изделия и часы',
    'услуги по установке окон, дверей',
    'детские товары',
    'прочие'
]

export const appealWayOptions = [
    'Устно/телефон',
    'Устно/офис',
    'Письменно/сайт',
    'Письменно/email',
    'Письменно/почта',
    'Письменно/офис',
    'Письменно/портал (к)',
    'Письменно/портал (о)',
    'С портала ЗПП',
]