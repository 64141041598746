import * as yup from 'yup';
import { DateTime } from "luxon";
import i18next from 'src/modules/i18n';
import Reference from 'yup/lib/Reference';
import { testTime } from './fieldValidators';
import { checkFairApplicationCreation } from 'src/utils/application';
import { formatLocaleDate } from 'src/utils/luxon/luxon.utils';
import { isEmpty } from 'lodash';
import { Fair } from 'src/models/fair/fair';

const t = i18next.getFixedT(null, null, 'validation');

declare module "yup" {
    interface StringSchema {
        testDate(): StringSchema;
        testLocaleDate(): StringSchema;
        testTime(): StringSchema;
        testTimePeriod(): StringSchema;
        isEarlierTime(value: string | Reference<unknown>): StringSchema;
        isLessStartDate(ref: string | Reference<unknown>, canBeOneDay?: boolean, msg?: string): StringSchema;
        isDateFromFuture(msg?: string): StringSchema;
        testNumbersCount(value: number): StringSchema;
        transformMaskValue(): StringSchema;
        checkPlacesCount(value: number | Reference<unknown>): StringSchema;
        testTimePeriod(): StringSchema;
        isCorrectApplicationDate(message?: string): StringSchema
    }
    interface NumberSchema{
        testDecimalNumber(): NumberSchema;
    }
    interface ArraySchema<T> {
        uniquePlaceNumbers(propertyName: string, message?: string) : ArraySchema<T>
        checkPlacesCount(ref: Reference<unknown>) : ArraySchema<T>
    }
}

yup.addMethod<yup.StringSchema>(yup.string, "testDate", function (this) {
    return this.test("testDate", t("date.incorrect"), (value) => {
        return value ? DateTime.fromISO(value).isValid : true
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "testLocaleDate", function (this) {
    return this.test("testLocaleDate", t("date.incorrect"), (value) => {
        return value && value !== '__.__.____' ? DateTime.fromFormat(value, 'dd.MM.yyyy').isValid : true
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "isLessStartDate", function (this, ref, canBeOneDay = false, msg) {
    return this.test({
        name: 'isLessStartDate',
        exclusive: false,
        params: { reference: ref ? ref.path : undefined },
        test: function (value) {
            const startDate = this.resolve(ref);
            if (!value || !startDate) return true; // || !DateTime.fromISO(startDate).isValid

            if (canBeOneDay) {
                return DateTime.fromISO(startDate) <= DateTime.fromISO(value)
            } else {
                return DateTime.fromISO(startDate) < DateTime.fromISO(value)
            }
        },
        message: msg ?? t("date.afterMin")
    })
});

yup.addMethod<yup.StringSchema>(yup.string, "isDateFromFuture", function (msg) {
    const currentDate = DateTime.now()
    return this.test({
        name: 'isDateFromFuture',
        exclusive: false,
        test: function (value) {
            if (!value) return true; // || !DateTime.fromISO(startDate).isValid
            return currentDate > DateTime.fromISO(value)
        },
        message: msg ?? 'Дата не может быть позже текущей даты'
    })
});

yup.addMethod<yup.StringSchema>(yup.string, "testNumbersCount", function (this, stringLength) {
    return this.test("testNumbersCount", t("string.length", { length: stringLength }), (value) => {
        if (value == undefined) return true;
        const digits = value.replace(/[^0-9]/g,"")
        return digits.length ? digits.length === stringLength : true
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "transformMaskValue", function (this) {
    return this.transform((value) => {
        if (!value) return
        const indexSkip = value.indexOf('_')
        if (indexSkip !== -1) return value.slice(0, indexSkip)
        return value
    });
});

yup.addMethod<yup.StringSchema>(yup.string, "checkPlacesCount", function (this, ref) {//
    return this.test({
        name: 'checkPlacesCount',
        exclusive: false,
        params: { reference: ref ? ref.path : undefined },
        test: function (value) {
            if(!value) return true
            const uniqueValues = new Set(value.split(', '))
            uniqueValues.delete('')
            if (this.resolve(ref) === uniqueValues.size) return true
            return false
        },
        message: t('string.placesNumbers')
    })
});

yup.addMethod<yup.StringSchema>(yup.string, "testTime", function (this) {
    return this.test(
        "testTime", 
        t('time.incorrect'), 
        (value) => value && value.replace(/[^0-9]/g,"").length ? testTime.test(value) : true
    );
});

yup.addMethod<yup.StringSchema>(yup.string, "isEarlierTime", function (this, ref) {
    return this.test({
        name: 'isEarlierTime',
        exclusive: false,
        message: t("time.afterMin"),
        params: { reference: ref ? ref.path : undefined },
        test: function (value) {
            const startTime = this.resolve(ref) ? DateTime.fromFormat(this.resolve(ref), 'HH:mm') : null;
            const endTime = value ? DateTime.fromFormat(value, 'HH:mm') : null;

            if (!startTime?.isValid || !endTime?.isValid) return true;
            return startTime < endTime
        },        
    })
});

yup.addMethod<yup.StringSchema>(yup.string, "testTimePeriod", function (this) {
    return this.test(
        "testTimePeriod", 
        t('time.incorrectPeriod'), 
        (value) => { //'с 99:99 до 99:99'
            if (!value) return true
            const startTime = value.substring(2, 7)
            const endTime = value.substring(11, 16)
            return testTime.test(startTime) && testTime.test(endTime) && startTime < endTime
        }
    );
});

yup.addMethod<yup.NumberSchema>(yup.number, "testDecimalNumber", function (this) {
    return this.test("testDecimalNumber", t("number.decimal"), (value) => {
        if (value != undefined) return /^\d+(\.\d{0,2})?$/.test(value.toString());
        return true;
    });
});

yup.addMethod<yup.StringSchema>(yup.string, 'isCorrectApplicationDate', function(message?) {
    return this.test('isCorrectApplicationDate', message, function(value) {
        const fair = this.parent.fair as Fair
        const time = this.parent.time

        if(!value || !fair) return true
        const currentDate = DateTime.fromFormat(`${value} ${time}`, 'yyyy-MM-dd HH:mm')

        if (isEmpty(fair.additionalInfo)){
            const startDate = fair.acceptingApplicationsStartDate
            const endDate = fair.acceptingApplicationsEndDate
            const startTime = fair.acceptingApplicationsStartTime
            const endTime = fair.acceptingApplicationsEndTime
            let message = `Дата должна быть между ${formatLocaleDate(startDate)} ${startTime} и ${formatLocaleDate(endDate)} ${endTime}`
    
            const isCreateApplicationAccess = checkFairApplicationCreation({
                startDate,
                endDate,
                startTime,
                endTime,
                currentDate
            })
    
            if(!isCreateApplicationAccess){
                return this.createError({
                    message,
                    path: this.path
                })
            }
        }

        if (fair.additionalInfo?.length) {
            const isCreateAdditionalApplicationAccess = fair.additionalInfo.findIndex((additional) => (
                checkFairApplicationCreation({
                    startDate: additional.acceptingStartDate,
                    endDate: additional.acceptingEndDate,
                    startTime: additional.acceptingStartTime,
                    endTime: additional.acceptingEndTime,
                    currentDate
                })
            )) !== -1

            if (!isCreateAdditionalApplicationAccess) {
                const correctDates = fair.additionalInfo.map(el => 
                    `с ${formatLocaleDate(el.acceptingStartDate)} ${el.acceptingStartTime} до ${formatLocaleDate(el.acceptingEndDate)} ${el.acceptingEndTime}`
                )
                return this.createError({
                    message: `Даты доп. приема: ${correctDates.join(', ')}`,
                    path: this.path
                })
            }
        }

        return true
    })
});

yup.addMethod(yup.array, 'uniquePlaceNumbers', function(propertyName, message?) {
    return this.test('unique', message, function(value) {
        if(!value) return true
        let errors: yup.ValidationError[] = []
        const items = new Set()
        value.forEach(((el, index) => {
            el[propertyName].split(',').forEach((element: string) => {
                const place = element.trim()
                if (Boolean(place) && items.has(place))        
                    errors.push(
                    this.createError({
                        path: `${this.path}[${index}].${propertyName}`,
                        message: message ?? `Торговое место под номером ${place} занято`
                      })); 
                if(Boolean(place))
                items.add(place)
            });
        }))
        if(errors.length === 0) return true
        return this.createError({message: () => errors}) 
    })
});

yup.addMethod(yup.array, "checkPlacesCount", function (this, ref) {
    return this.test({
        name: 'checkPlacesCount',
        exclusive: false,
        message: 'Количество мест, указанное в товарных группах, не совпадает с общим числом мест на ярмарке',
        params: { reference: ref ? ref.path : undefined },
        test: function (value) {
            const placesCount = this.resolve(ref) ?? 0;
            if(!value) return true
            if(!placesCount) return true

            value.forEach(((el, index) => {
                const sumSectorPlacesCount = value.slice(0, index + 1).reduce(
                    (acc, sector) => acc + sector.tradingPlacesNumber,
                    0
                );
                if (+placesCount < +sumSectorPlacesCount) {
                    throw this.createError({
                        path: `${this.path}[${index}].tradingPlacesNumber`,
                        message: "Количество мест, указанное в товарных группах, не совпадает с общим числом мест на ярмарке"
                    });
                }
            }))
            return true
        },
    })
});

export default yup;