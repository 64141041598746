import { combineReducers } from 'redux';
import { api } from 'src/api/baseRtk.api';
import auth from './auth/authSlice';
import layout from './layout/layoutSlice';

export default combineReducers({
  layout,
  auth,
  [api.reducerPath]: api.reducer,
});

