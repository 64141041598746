import { Provider } from 'react-redux'
import RoutesBuilder from 'src/components/routing/RoutesBuilder';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './App.css'
import { SnackbarUtilConfigurator } from 'src/utils/notistack/snackbar.util';
import { theme } from './config/theme';
import { store } from './store';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { history } from 'src/components/routing/browserHistory'
import ScrollToTop from './components/layout/ScrollToTop';

const App = () => {
  return (
    <Provider store={store}>
      <HistoryRouter history={history}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <SnackbarUtilConfigurator />
            <CssBaseline />
            <ScrollToTop/>
            <RoutesBuilder />
          </SnackbarProvider>
        </ThemeProvider>
      </HistoryRouter>
    </Provider>
  );
}

export default App