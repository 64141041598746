const inspection = {
    changeLog: 'История изменений',

    //обращение
    appeal: {
        number: 'Номер обращения',
        type: 'Вид обращения',
        way: 'Способ обращения',
        content: 'Содержание обращения',
        date: 'Дата обращения',
        appealAdditionDocs: 'Приложенные документы к обращению',
        appealInspectionDocs: 'Документы проверки',
        registrationNumber: 'Номер на портале ЗПП',
        inspector: 'ФИО ответственного сотрудника',
        inspectionResult: 'Результат проведенной проверки',
        registrationNumberCspr: 'Регистрационный номер',

        //заявитель
        applicant: {
            fullName: 'ФИО заявителя',
            email: 'Электронная почта',
            postalAddress: 'Почтовый адрес',
            phoneNumber: 'Номер телефона',
        },

        //продукт
        product: {
            group: 'Группа продукции',
            kind: 'Вид продукции',
            name: 'Наименование товара/услуги',
            productionDate: 'Дата изготовления',
            weight: 'Масса нетто',
            volume: 'Объем',
            price: 'Цена товара (руб)',
            manufacturer: 'Изготовитель',
            company: 'Наименование торгового предприятия',
            companyAddress: 'Адрес торгового предприятия',
            purchaseDate: 'Дата покупки',
            isReceiptSaved: 'Чек сохранен',
            type: 'Тип продукции',
            category: 'Категория',
            class: 'Класс продукции',
        }
    },

    //проверка
    refferal: {
        number: 'Номер направления',
        registrationNumberCspr: 'Номер акта проверки',
        dateOf: 'Время проведения проверки',
        actDateOf: 'Дата проведения проверки',
        inspectionReason: 'На основании',//Основание для проведения проверки
        status: 'Статус',
        startTime: 'Время начала проверки',
        endTime: 'Время окончания проверки',
        normativeDocuments: 'Нормативные документы (НД, НДА)',

        //Ответственные сотрудники
        inspector: 'ФИО ответственного сотрудника',
        inspectorPosition: 'Должность ответственного сотрудника',
        inspectionTeamTestCoordinatorFullName: 'ФИО координатора проверки',
        inspectionTeamTestCoordinatorPosition: 'Должность координатора проверки',
        inspectionTeamHeadFullName: 'ФИО руководителя ЦККТРУ',
        inspectionTeamHeadPosition: 'Должность руководителя ЦККТРУ',

        //Результат проверки
        result: 'Результат проведенной проверки',
        conclusions: 'Выводы и предложения по результатам',
        actAdditionComment: 'К акту прилагаются:',
        actAdditionDocs: 'Приложение к акту проверки',

        //Торговое предприятие
        tradeCompany: {
            name: 'Наименование',
            address: 'Адреc',
            phoneNumber: 'Номер телефона',
            headFullName: 'Полное имя руководителя',
            headPosition: 'Должность руководителя',
            sampleStorageArea: 'Место хранения образцов',
            reprFullName: 'ФИО представителя',
            reprPosition: 'Должность представителя',
        },

        //Для истории изменений
        tradeCompanyName: 'Наименование торгового предприятия',
        tradeCompanyAddress: 'Адреc торгового предприятия',
        tradeCompanyPhoneNumber: 'Номер телефона торгового предприятия',
        tradeCompanyHeadFullName: 'Полное имя руководителя торгового предприятия',
        tradeCompanyHeadPosition: 'Должность руководителя торгового предприятия',
        tradeCompanySampleStorageArea: 'Место хранения образцов',
        tradeCompanyReprFullName: 'ФИО представителя торгового предприятия',
        tradeCompanyReprPosition: 'Должность представителя торгового предприятия',
    },

    //Cоглашение с торговой сетью
    tradeAgreement: {
        contractConclusionDate: 'Дата заключения договора',
        contractGrounds: 'Основание заключения договора',
        controlCenterSigner: 'Подписант со стороны ЦККТРУ',
        additionalAgreement: 'Сведения о дополнительном соглашении',
        name: 'Наименование торговой сети',
        legalAddress: 'Юридический адрес торговой сети',
        inn: 'ИНН',
        kpp: 'КПП',
        contactPerson: 'Контактное лицо',
        contactPersonPhoneNumber: 'Номер телефона контактного лица',
        signer: 'Подписант со стороны торговой сети',
    },

    //ответственный сотрудник
    inspector: {
        lastName: 'Фамилия',
        firstName: 'Имя',
        middleName: 'Отчество',
        post: 'Должность',
        shortName: 'ФИО сокращенно',
        shortNameGenitiveCase: 'ФИО сокращенно (в творительном падеже)',
    },

    employee: {
        email: 'Электронная почта',
        fullName: 'ФИО',
        phoneNumber: 'Номер телефона',
        shortNameGenitiveCase: 'ФИО сокращенно (в творительном падеже)',
        workingPosition: 'Должность',
        companyFullName: 'Наименование организации',
        role: 'Роль',
        shortName: 'ФИО сокращенно',
    },
    //Заключение по проверке
    inspectionConclusion: {
        result: 'Результат проведенной проверки',
        conclusions: 'Выводы и предложения по результатам',
        documents: 'Акт проверки и приложение к акту проверки', //ответ потребителю
        refusalDocuments: 'Ответ заявителю в случае отказа',
    },

    //Образец
    productSample: {
        status: 'Статус образца',
        manufacturerCountry: 'Страна-производитель',
        manufacturer: 'Предприятие-производитель',
        supplier: 'Поставщик',
        group: 'Продовольственная группа',
        name: 'Наименование товара',
        number: 'Номер образца',
        quantity: 'Количество образца',
        productionDate: 'Дата изготовления/дата выработки',
        expirationDate: 'Срок годности/годен до',
        packagingDate: 'Дата фасовки/упаковки',
        weight: 'Вес (масса нетто)',
        volume: 'Объем',
        ttnNumber: 'Номер ТТН',
        ttnDate: 'Дата ТТН',
        ttnLotSize: 'Размер партии (согласно ТТН)',
        selectedLotSize: 'Размер партии, от которой отобран образец',
        selectedProductNormativeDocuments: 'НД, в соответствии с которым отобран образец',
        developmentProductNormativeDocuments: 'НД, по которому выработан продукт',
        packaging: 'Упаковка образца',
        packagingCondition: 'Состояние упаковки (тары)',
        selectionActNote: 'Примечание к акту отбора',
        requirementsComplianceComment: 'Документы, подтверждающие соответствие товара установленным требованиям',
        requirementsComplianceDocs: 'Приложенные документы, подтверждающие соответствие товара установленным требованиям',
        signedLabRefferal: 'Подписанное направление на исследование',
        registrationDate: 'Дата регистрации',
        samplingInformation: 'Сведения об отборе',
        additionalInformation: 'Дополнительные сведения',

        testProgram: {
            organolapticIndicators: 'Органолептические показатели',
            safetyIndicators: 'Показатели безопасности',
            physicalChemicalIndicators: 'Физико-химические показатели',
            microbiologicalIndicators: 'Микробиологические',
            toxicElements: 'Токсичные элементы',
            pesticides: 'Пестициды',
            mycotoxins: 'Микотоксины',
            radionuclides: 'Радионуклиды',
            nitrates: 'Нитраты',
            nitrites: 'Нитриты',
            histologicalIdentification: 'Гистологическая идентификация',
            antibiotics: 'Антибиотики',
            benzopyrene: 'Бенз(а)пирен',
            nitrosamines: 'Нитрозамины',
            polychlorinatedBiphenyls: 'Полихрорированные бифенилы',
            histamine: 'Гистамин',
            oxymethylfurfural: '5-оксиметилфурфурол',
            harmfulImpurities: 'Вредные примеси',
            gmo: 'ГМО',
            oxidativeDamage: 'Показатели окислительной порчи (перекисное число, кислотное число)',
        }
    },

    //статусы образца
    productSampleStatus: {
        received: 'Поступил',
        tested: 'В работе',
        completed: 'Завершено'
    },

    //статусы проверки
    refferalStatus: {
        refferalPreparation: 'Подготовка направления',
        inspectionDataEntering: 'Заполнение данных проверки и образцов',
        testing: 'Проведение исследований',
        completed: 'Завершено',
        conclusionPending: 'Ожидает вынесения заключения',
        closed: 'Закрыто',
    },

    //протокол испытаний
    testProtocol: {
        number: 'Номер протокола',
        registrationDate: 'Дата оформления протокола',
        approvalDate: 'Дата утверждения протокола',
        testStartDate: 'Дата начала испытаний',
        testEndDate: 'Дата окончания испытаний',
        conclusion: 'Заключение по испытаниям',
        testIndicators: {
            group: 'Группа показателей',
            name: 'Наименование показателя',
            normativeValue: 'Нормативное значение',
            actualValue: 'Фактическое значение',
            normativeDocument: 'НД на методику испытаний',
        },
    },

    //История изменений
    history: {
        appeal: 'Обращения',
        product: 'Товары в обращении',
        applicant: 'Заявители',
        inspectionTeam: 'Ответственные сотрудники',
        tradeAgreement: 'Соглашения с компанией (торговой сетью)',
        productSample: 'Образцы',
        testProtocol: 'Протокол испытаний  ',
        refferal: 'Направления',
        commercialNetwork: 'Компания, с которой заключено соглашение',
        inspectionConclusion: 'Заключения',
        inspectionDetail: 'Информация о проверке',
        tradeCompany: 'Торговые предприятия',
        testProgram: 'Программа тестирования',
        testIndicator: 'Индикаторы для проведения исследования',
    }
}

export default inspection