import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAccessControl } from 'src/api/auth/hooks/useAccessControl';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { UserRole } from 'src/config/rolesPermissionsMap';

export interface MenuItemProps {
    roles?: UserRole[]
    children?: React.ReactNode | React.ReactNode[]
    label: string
    component?: React.ReactNode
    url?: string
    icon?: React.ReactNode
    depth?: number
    excludeAdmin?: boolean
}

const MenuItem = (props: MenuItemProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { pathname } = useLocation();
    const excludeAdmin = props.excludeAdmin ?? false;

    const { isAllowed, isAdmin } = useAccessControl({
        roles: props.roles
    });

    if (!isAllowed || isAdmin && excludeAdmin) return null;

    let expandIcon = null
    if (props.children) {
        expandIcon = isOpen ? <ExpandLess /> : <ExpandMore />
    }

    const listItemButton = <ListItemButton
        selected={pathname === props.url}
        onClick={() => {
            if (props.children)
                setIsOpen(!isOpen)
        }}>
        {props.icon && <ListItemIcon>
            {props.icon}
        </ListItemIcon>}
        {props.component ?? <ListItemText primary={props.label}/>}
        {expandIcon}
    </ListItemButton>;

    if (props.children)
        return <>
            {listItemButton}
            <Collapse in={isOpen} unmountOnExit>
                {props.children}
            </Collapse>
        </>

    return <Link
        to={props.url ?? '#'}
        style={{ color: 'inherit', textDecoration: 'none' }}
    >
        {listItemButton}
    </Link>;
}

export default MenuItem