import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary']
        active: Palette['primary']
    }
    interface PaletteOptions {
        neutral: PaletteOptions['primary']
        active: PaletteOptions['primary']
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true
        active: true
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        active: true
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: '#202225',
            light: '#4d4e51'
        },
        secondary: {
            dark: '#DB6D2D',
            main: '#E89160',
            light: '#FFF3EC',
            contrastText: '#fff',
        },
        neutral: {
            main: '#8793A1',
            contrastText: '#fff',
        },
        active: {},
        success: {
            main: '#43bb10'
        }
    },
    typography: {
        fontSize: 14,
        fontFamily: 'Lato',

        h3: {
            fontWeight: 700,
            marginBottom: '2.5rem',
            fontSize: '1.7rem'
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.7rem',
            textOverflow: 'ellipsis', 
            overflow: 'hidden', 
            whiteSpace: 'nowrap',
        },
        h5: {
            fontWeight: 700,
            fontSize: '1.3rem'
        },
        h6: {
            fontWeight: 700,
            color: '#E89160'
        },

        body1: {
            color: '#202225',
        },
        body2: {
            color: '#4d4e51',
            lineHeight: '1.2rem',
            fontSize: '1rem'
        },
        subtitle1: {
            fontWeight: 600, 
            fontSize: 18
        },
        subtitle2: {
            color: '#202225',
            display: 'inline',
            fontWeight: 600,
            lineHeight: '1.2rem',
            fontSize: '1rem'
        },
    },
})
