import { AgGridInfiniteGetResponse } from "src/config/globalConfig";
import { Document } from "../document/document";

export enum NotificationType {
    Fair = 'fair',
    Market = 'market',
    Inspection = 'inspection'
}

export interface Notification {
    id: string;
    type: string;
    data: {
        title: string;
        message: string;
        documents?: Document[];
    } & Record<string, any>
    readAt: string | null;
    createdAt: string
}

export type NotificationList = AgGridInfiniteGetResponse<Notification> & {
    readAt: boolean | null //прочитаны все уведомления
    notRead: number //всего непрочитано
}