
import i18next from 'src/modules/i18n';
import yup from "./yupMethods";
import { getMaxLengthMessage, getMinLengthMessage } from "src/utils/forms/validationFunction.util";
import { testPhone } from './fieldValidators';

const t = i18next.getFixedT(null, null, 'validation');
const reqMessage = t("mixed.required");

//for documents
yup.setLocale({
    mixed: {
        defined: t('documents.required'),
    },
});

const requiredStringValidation = (props?: { minLength?: number, maxLength?: number }) => (
    yup.string()
        .trim()
        .nullable()
        .required(reqMessage)
        .min(props?.minLength ?? 3, (obj) => getMinLengthMessage(obj.min))
        .max(props?.maxLength ?? 80, (obj) => getMaxLengthMessage(obj.max))
)

const optionalStringValidation = (props?: { maxLength?: number }) => (
    yup.string()
        .trim()
        .optional()
        .nullable()
        .max(props?.maxLength ?? 80, (obj) => getMaxLengthMessage(obj.max))
)

const integerNumberValidation = yup
    .number()
    .typeError(t("number.number"))
    .required(reqMessage)
    .positive(t("number.positive"))
    .integer(t("number.integer"));

const decimalNumberValidation = yup
    .number()
    .typeError(t("number.number"))
    .nullable()
    .positive(t("number.positive"))
    .testDecimalNumber()
    .transform((val) => val === Number(val) ? val : null);

const dateValidation = yup
    .string()
    .nullable()
    .testDate();

const timeValidation = yup
    .string()
    .nullable()
    .testTime();

const documentValidation = yup
    .array()
    .defined()
    .min(1, t('documents.required'));

const filterFieldValidation = yup
    .string()
    .trim()
    .nullable()
    .transform((value) => value || undefined) //при value === null поле отсылается в query parameters, что мешает работе фильтра на стороне бэка
    .max(1000, (obj) => getMaxLengthMessage(obj.max));

const phoneValidation = yup
    .string()
    .nullable()
    .transform((value) => value || undefined)
    .matches(testPhone, t("string.phoneNumber"));

export { 
    requiredStringValidation,
    optionalStringValidation,
    integerNumberValidation,
    decimalNumberValidation,
    dateValidation,
    timeValidation,
    documentValidation,
    filterFieldValidation,
    reqMessage,
    phoneValidation,
};
export default yup;