
import i18next from 'src/modules/i18n';
const t = i18next.getFixedT(null, null, '');

export const getMaxLengthMessage = (length: number, field?: string) : string => {
    return t("validation.string.max", { max: length, path: field ? `"${t(`formLabels.${field}`).toLowerCase()}"` : '' })
}

export const getMinLengthMessage = (length: number, field?: string) : string =>  {
    return t("validation.string.min", { min: length, path: field ? `"${t(`formLabels.${field}`).toLowerCase()}"` : '' })
}
