import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserPermission, UserRole } from 'src/config/rolesPermissionsMap';
import { useAccessControl } from 'src/api/auth/hooks/useAccessControl';
import Layout from '../layout/Layout';
import AuthMiddleware from 'src/views/auth/AuthMiddleware';

interface PublicRouteProps {
  children: React.ReactNode
  roles?: UserRole[]
  permissions?: UserPermission[]
}

const PrivateRoute = ({ children, roles, permissions }: PublicRouteProps) => {
  const { isAllowed } = useAccessControl({ roles, permissions })

  if (!isAllowed) {
    return <Navigate to="/404" replace />
  }

  return (<AuthMiddleware>
    <Layout>
      {children}
    </Layout>
  </AuthMiddleware>);
}

export default PrivateRoute;

