import { Box, Grid, Stack, Typography } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

type FooterItem = {
    title: string
    phoneNumber: string
    email: string 
}

const footerItems: FooterItem[] = [
    // {
    //     title: 'Методическое информационное обеспечение (рынки)',
    //     phoneNumber: '-',
    //     email: 'cspr@sspk.spb.ru'
    // },
    {
        title: 'Методическое информационное обеспечение (ярмарки)',
        phoneNumber: '+7 (812) 498-78-37',
        email: 'yarmarka@quality.spb.ru'
    },
    {
        title: 'Служба технической поддержки',
        phoneNumber: '+7 (812) 246-84-01',
        email: 'cspr@sspk.spb.ru'
    },
    {
        title: 'Хасанский рынок',
        phoneNumber: '8 (901) 301-01-44, 577-24-53, 577-21-55',
        email: 'kontrolerhas@quality.spb.ru'
    },
    {
        title: 'Гражданский рынок',
        phoneNumber: '449-13-78, 449-13-76, 449-13-70',
        email: 'kontrograjd@quality.spb.ru'
    },
    {
        title: 'Кузнечный рынок',
        phoneNumber: '8 (901) 305-97-98',
        email: 'kontroler@quality.spb.ru'
    },
]

const FooterItem = ({ item }: { item: FooterItem }) => (
    <Grid item xs={6} sm={4} lg={2.4}>
        <Typography color='inherit' fontSize='0.9rem'>
            {item.title}
        </Typography>

        <Stack direction='row' gap={1} alignItems='center'>
            <LocalPhoneIcon fontSize='small' />
            <Typography color='inherit' fontSize='0.9rem'>
                {item.phoneNumber}
            </Typography>
        </Stack>

        <Stack direction='row' gap={1} alignItems='center'>
            <AlternateEmailIcon fontSize='small' />
            <Typography color='inherit' fontSize='0.9rem'>
                {item.email}
            </Typography>
        </Stack>
    </Grid>
)

export const Footer = () => {
    return (
        <Box sx={{ pt: 1, px: 2, pb: 2, backgroundColor: 'neutral.main', color: 'neutral.contrastText'}}>
            <Grid 
                container
                rowSpacing={2}
                columnSpacing={4}
                // sx={{ maxWidth: 1500 }}
            >
                {footerItems.map(item => <FooterItem key={item.title} item={item} />)}
            </Grid>
        </Box>
    );
};