const market = {
    //рынки
    market: {
        name: 'Наименование рынка',
        type: 'Тип рынка',
        address: 'Адрес рынка',
        workingTime: 'Режим работы рынка',
        productGroups: 'Товарные группы, представляемые на рынке',
        tradingPlacesNumber: 'Количество торговых мест на рынке',
        permitInfo: 'Информация о разрешении на право организации рынка',
        permitNumber: 'Номер разрешения',
        managementCompany: 'Наименование Управляющей компании',
        legalForm: 'Организационно-правовая форма Управляющей компании',
        permitIssueDate: 'Дата выдачи разрешения',
        permitExpirationDate: 'Дата окончания срока действия разрешения',
        interactivePlanLink: 'Ссылка на интерактивную план-схему рынка',
        placementPlan: 'План размещения торговых мест',
        placeNumber: 'Номер торгового места'
    },
    //торговое место на рынке
    marketPlace: {
        createdAt: 'Дата и время подачи заявки',
        productGroup: 'Товарная группа',
        isEquipment: 'Наличие торгового оборудования',
        number: 'Номер торгового места',
        numbers: 'Номера торговых мест',
        cost: 'Cтоимость торгового места (руб. в месяц)',
        measureUnit: 'Единица измерения',
        meterCost: 'Стоимость одного метра (руб. в месяц)',
        area: 'Площадь торгового места',
        areas: 'Площадь торговых мест',
        status: 'Cтатус торгового места',
        startProvide: 'Дата начала предоставления торговых мест',
        endProvide: 'Дата окончания предоставления торговых мест',
        periodProvide: 'Период предоставления торговых мест',
        purpose: 'Цель использования торговых мест',
        location: 'Расположение торгового места',
        type: 'Тип торгового места',
        optionalEquipment: '',
        equipmentType: 'Тип оборудования (при наличии)',
        totalCost: 'Размер платы',
    },
    //документы для рынка от заявителей
    marketApplicationDocuments: {
        producerInfo: 'Информация о товаропроизводителе, в том числе об осуществляемом им виде деятельности в соответствии с ОКВЭД',
        innDoc: 'Отсканированная копия ИНН',
        producerInfoAddition: 'В случае предоставления торгового места товаропроизводителю в соответствии с частью 2 статьи 16 Федерального закона от 30.12.2006 № 271-ФЗ «О розничных рынках и о внесении изменений в Трудовой кодекс Российской Федерации», а также о классе предполагаемых к продаже на рынке товаров в соответствии с номенклатурой товаров, установленной федеральным органом исполнительной власти, осуществляющим функции по выработке государственной политики и нормативно-правовому регулированию в сфере торговли',
        identityEntityDoc: 'Копия документа, удостоверяющего личность руководителя',
        confirmingСompetenceEntityDoc: 'Документ, подтверждающий полномочия уполномоченного представителя, заверенный подписью руководителя и оттиском печати юридического лица (при наличии), или документ, подтверждающий факт избрания (назначения) на должность руководителя юридического лица',
        confirmingEnteringInfoEntityDoc: 'Копия документа, подтверждающего факт внесения сведений о юридическом лице в ЕГРЮЛ',
        identityEntrepreneurDoc: 'Копия документа, удостоверяющего личность индивидуального предпринимателя',
        confirmingСompetenceEntrepreneurDoc: 'Документ, подтверждающий полномочия уполномоченного представителя, заверенный подписью руководителя и оттиском печати индивидуального предпринимателя (при наличии), документ, содержащий сведения о регистрации в качестве индивидуального предпринимателя',
        confirmingEnteringInfoEntrepreneurDoc: 'Копия документа, подтверждающего факт внесения сведений об индивидуальном предпринимателе в ЕГРИП',
        identityIndividualDoc: 'Копия документа, удостоверяющего личность физического лица',
        confirmingСompetenceIndividualDoc: 'Копия документа, подтверждающего членство физического лица в крестьянском (фермерском) хозяйстве, ведение личного подсобного хозяйства или занятие садоводством, огородничеством, животноводством',
        proxyIdentityDoc: 'Копия паспорта представителя',
        proxyConfirmingDoc: 'Копия нотариальной доверенности на осуществление деятельности от имени участника отбора',
        identityDocComment: '(необходимо приложить отсканированную копию 2 и 3 страницы паспорта, а также страницы с актуальными данными по регистрации)'
    },
    //контракты
    marketContract: {
        contractNumber: 'Номер договора',
        conclusionContractDate: 'Дата заключения договора',
        fullName: 'Заявитель',
        number: '№ п/п',
        status: 'Статус',
        approvalDate: 'Дата утверждения договора',
    },
    //заявление
    marketApplication: {
        registrationNumber: 'Регистрационный номер',
        createdAt: 'Дата создания заявления',
        rejectReason: 'Причина отказа в предоставлении торгового места на рынок',
    },
    marketApplicationStatus: {
        created: 'Создано',
        considered: 'На рассмотрении',
        signingContract: 'Подписание договора',
        accepted: 'Принято',
        rejected: 'Отклонено'
    }
}

export default market