import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/api/auth/hooks/useAuth";
import Spinner from "src/components/common/Spinner";
import { useAppDispatch } from "src/store";

interface AuthMiddlewareProps {
    children: JSX.Element
}

const AuthMiddleware = (props: AuthMiddlewareProps) => {
    const dispatch = useAppDispatch();
    const { isAuthenticated, isUserProfileLoading } = useAuth();


    if (isUserProfileLoading) {
        return <Spinner />
    }

    if (!isAuthenticated) {
        return <Navigate to="/signin" replace />
    }

    return props.children
}

export default AuthMiddleware;